import { BLIState as State } from '@/store';

const state: State = {
  policy: {
    publicGeneral: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    statutory: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    employers: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    directorsAndOfficers: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    employeeDisputes: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    crimeFidelity: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    legalProsecutionDefenceCosts: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    insurer: {
      footnotes: undefined,
      insurerName: undefined,
      moreInfo: undefined,
      rating: undefined,
      text: undefined
    }
  },
  answers: {
    industrialSites: undefined,
    industrialWorkTypes: undefined,
    isHotwork: undefined,
    isMadeClaim: undefined,
    bliLiabilityClaims: [],
    publicGeneral: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    statutory: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    employers: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    directorsAndOfficers: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    employeeDisputes: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    crimeFidelity: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    legalProsecutionDefenceCosts: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    }
  }
};

export default state;
