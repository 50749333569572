


























import Vue, { PropType } from 'vue';
import moment from 'moment-timezone';
import Component from 'vue-class-component';

@Component
export default class BiDatepicker extends Vue.extend({
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    minDate: {
      type: [String, Object] as PropType<string | Date>,
      required: false,
      default: '',
    },
  },
}) {
  get coverStartDate(): Date | undefined {
    return moment(this.value, 'DD/MM/YYYY').toDate();
  }
  set coverStartDate(value: Date | undefined) {
    this.$emit('input', moment(value).format('DD/MM/YYYY'));
  }
}
