


































import { PolicySpecifiedItem } from '@/api';
import Vue from '@/vue';
import Component from 'vue-class-component';
import BiItemItem from './bi-item-item.vue';

@Component
export default class BiItemList extends Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: 'Item',
    },
    value: {
      type: Array as () => Array<PolicySpecifiedItem>,
      default: (): PolicySpecifiedItem[] => [],
    },
  },
}) {
  touch(): void {
    if (this.$refs.item) {
      for (const item of this.$refs.item as BiItemItem[]) {
        item.touch();
      }
    }
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }

  addItem(): void {
    this.value.push({
      item: undefined,
      itemValue: undefined,
    });
    this.emitInput();
  }

  removeItem(index: number): void {
    this.value.splice(index, 1);
    this.emitInput();
  }
}
