


































import { PolicyDirector } from '@/api';
import Vue from '@/vue';
import Component from 'vue-class-component';
import BiPersonItem from './bi-person-item.vue';

@Component
export default class BiPersonList extends Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: 'Claim',
    },
    'add-button-text': {
      type: String,
      required: false,
      default: 'Add Person',
    },
    'remove-button-text': {
      type: String,
      required: false,
      default: 'Remove Person',
    },
    value: {
      type: Array,
      default: (): PolicyDirector[] => [],
    },
  },
}) {
  touch(): void {
    if (this.$refs.person) {
      for (const person of this.$refs.person as BiPersonItem[]) {
        person.touch();
      }
    }
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }

  addItem(): void {
    this.value.push({
      name: '',
      experience: '',
      files: [],
    });
    this.emitInput();
  }

  removeItem(index: number): void {
    this.value.splice(index, 1);
    this.emitInput();
  }
}
