
import Vue from '@/vue';
import { CreateElement, PropType, RenderContext, VNode } from 'vue';
import Component from 'vue-class-component';

function createRows(c: CreateElement, rows: VNode[][][]): VNode[] {
  /*
   * rows > columns > elements
   */
  const rowEls: VNode[] = [];
  for (const row of rows) {
    const colEls: VNode[] = [];
    for (const col of row) {
      if (col.length === 0) {
        colEls.push(c('b-col', { props: { cols: 1 } }, col));
      } else {
        colEls.push(c('b-col', col));
      }
    }
    rowEls.push(c('b-row', colEls));
  }
  return rowEls;
}

function createGroup(
  c: CreateElement,
  context: RenderContext<{
    filter: string[];
    group: boolean;
    headingTextTop: string;
    headingTextBottom: string;
    headingElement: string;
    headingClasses: string[];
    headingOffset: string | number;
  }>,
  heading: string,
  views: VNode[]
) {
  /*
   * creates this layout
   * |-----------|
   * | label     |
   * |-----------|
   * | form view |
   * | form view |
   * |-----------|
   */
  if (context.scopedSlots.label !== undefined) {
    const labelElement = context.scopedSlots.label({ text: heading });

    const rows = createRows(c, [[labelElement || []], [views]]);
    return c('div', rows);
  } else {
    const labelElement = c(
      context.props.headingElement,
      {
        class: ['m-0', ...context.props.headingClasses, 'font-weight-normal'],
      },
      heading
    );
    const headingOffset =
      typeof context.props.headingOffset === 'string'
        ? parseInt(context.props.headingOffset)
        : context.props.headingOffset;
    const offsetElements: Array<never[]> = Array(headingOffset).fill([]);

    const rows = createRows(c, [[...offsetElements, [labelElement]], [views]]);
    return c('div', rows);
  }
}

@Component
export default class BiGrouper extends Vue.extend({
  functional: true,
  props: {
    filter: {
      type: Array as PropType<string[]>,
      required: true,
    },
    hidden: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    group: {
      type: Boolean,
      required: false,
      default: false,
    },
    headingTextTop: {
      type: String,
      required: true,
    },
    headingTextBottom: {
      type: String,
      required: true,
    },
    headingElement: {
      type: String,
      required: false,
      default: 'h5',
    },
    headingClasses: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    headingOffset: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    showHeading: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
  },
  render(c, context) {
    /*
     * creates two groups for renewals using views provided in default slot
     * or just displays the items in the slot as is if not a renewal
     *
     * renewal provided using `group` boolean prop
     */
    const views: VNode[] = context.slots().default || [];
    const visibleViews: VNode[] = [];

    for (const view of views) {
      if (view.key !== undefined) {
        const viewHidden =
          context.props.hidden.findIndex(
            (item) => view.key?.toString().toLowerCase() === item.toLowerCase()
          ) !== -1;

        if (viewHidden === false) {
          visibleViews.push(view);
        }
      } else {
        visibleViews.push(view);
      }
    }

    if (visibleViews.length !== 0 && context.props.group === true) {
      const reviewViews: VNode[] = [];
      const otherViews: VNode[] = [];

      for (const view of visibleViews) {
        if (view.tag === undefined) {
          continue;
        }
        if (view.key !== undefined) {
          const viewInFilter =
            context.props.filter.findIndex(
              (item) =>
                view.key?.toString().toLowerCase() === item.toLowerCase()
            ) !== -1;
          if (viewInFilter === true) {
            reviewViews.push(view);
            continue;
          }
        }
        otherViews.push(view);
      }
      const groups: VNode[] = [];

      if (reviewViews.length !== 0) {
        groups.push(
          createGroup(c, context, context.props.headingTextTop, reviewViews)
        );
      }

      if (otherViews.length !== 0) {
        groups.push(
          c(
            'div',
            { class: ['position-relative', 'my-4', 'py-4'] },
            [
                 c('div', {
                  style: {
                    top: 0,
                    bottom: 0,
                    left: '-100vw',
                    right: '-100vw',
                    background: 'rgba(0, 0, 0, 0.03)',
                  },
                  class: ['position-absolute'],
                }),
              createGroup(c, context, context.props.headingTextBottom, otherViews
            )]
          )
        );
      }

      return groups;
    } else if (
      context.props.showHeading === true ||
      typeof context.props.showHeading === 'string'
    ) {
      const heading =
        typeof context.props.showHeading === 'string'
          ? context.props.showHeading
          : context.props.headingTextTop;
      return createGroup(c, context, heading, visibleViews);
    } else {
      return visibleViews;
    }
  },
}) {}
