export async function requote(this: Vue): Promise<string> {
  return new Promise((resolve, reject) => {
    this.$loading = true;
    this.$store
      .dispatch(this.$actions.package.action, {
        hash: this.$packageHash,
        action: 'requote'
      })
      .then(response => {
        resolve(`/quote/${response.data.hash}/your-details`);
      })
      .catch(e => {
        this.$loading = false;
        console.error(e);
        reject(e);
      });
  });
}

export function home(this: Vue): string {
  return '/';
}

export function dashboard(this: Vue): string {
  return this.$dashboardPath;
}
