import { MDTState } from '../states';

const state: MDTState = {
  policy: {
    toolsAndEquipment: {
      id: undefined,
      isInsured: undefined,
      coverOf: undefined,
      premium: undefined,
      refer: undefined
    },
    subTotal: undefined,
    isAvailable: undefined,
    insurer: {
      footnotes: undefined,
      insurerName: undefined,
      moreInfo: undefined,
      rating: undefined,
      text: undefined
    },
    financeCostPerMonth: undefined,
    isFormCompleted: undefined
  },
  answers: {
    id: undefined,
    isMadeClaim: undefined,
    toolValue: undefined,
    scaffoldingValue: undefined,
    mdtToolEquipmentClaims: [],
    specifiedItems: []
  }
};

export default state;
