import Vue from '@/vue';
import Vuex from 'vuex';

import global from './global';

import pkg from './package';
import lookups from './lookups';
import finance from './finance';
import liability from './liability';
import toolsAndEquipment from './toolsAndEquipment';
import commercialVehicles from './commercialVehicles';
import { State } from './states';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  strict: true,
  modules: {
    global,
    package: pkg,
    lookups,
    finance,
    liability,
    toolsAndEquipment,
    commercialVehicles,
  }
});

export * from './states';
export * from './storeMap';
