





































import { PolicyEvent } from '@/api';
import Vue from '@/vue';
import Component from 'vue-class-component';
import BiEventItem from './bi-event-item.vue';

@Component
export default class BiEventList extends Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: 'Penalty',
    },
    'add-button-text': {
      type: String,
      required: false,
      default: 'Add penalty',
    },
    'remove-button-text': {
      type: String,
      required: false,
      default: 'Remove penalty',
    },
    value: {
      type: Array,
      default: (): PolicyEvent[] => [],
    },
    dateMonthOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    dateYearOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    noPenalty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  touch(): void {
    if (this.$refs.penalty) {
      for (const item of this.$refs.penalty as BiEventItem[]) {
        item.touch();
      }
    }
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }

  addItem(): void {
    this.value.push({
      whatHappened: undefined,
      penalty: undefined,
      eventMonth: undefined,
      eventYear: undefined,
    });
    this.emitInput();
  }

  removeItem(index: number): void {
    this.value.splice(index, 1);
    this.emitInput();
  }
}
