
















import Vue from '@/vue';
import Component from 'vue-class-component';

@Component
export default class BiFsrScale extends Vue.extend({
  props: {
    maxRows: {
      type: [String, Number],
      required: false,
      default: 5,
    },
    data: {
      type: String,
      required: true,
      default: '',
    },
    itemClass: {
      type: String,
      required: false,
      default: '',
    },
  },
}) {
  get items(): string[] {
    let items = this.data.split(/\r?\n/);
    items = items.map((i) => i.trim());
    items = items.filter((i) => i !== '');

    while (items[0] === '') {
      items.shift();
    }
    while (items[items.length - 1] === '') {
      items.pop();
    }
    return items;
  }

  get rowCount(): number {
    if (typeof this.maxRows === 'string') {
      return parseInt(this.maxRows);
    } else {
      return Math.floor(this.maxRows);
    }
  }

  get columnCount(): number {
    return Math.ceil(this.items.length / this.rowCount);
  }

  getItemNumber(row: number, column: number, oneBased = false): number {
    if (oneBased) {
      return row - 1 + (column - 1) * this.rowCount;
    } else {
      return row + column * this.rowCount;
    }
  }
}
