import {
  LookupGeneral,
  LookupRegion,
  LookupSector,
  LookupTrade,
  LookupLossOfUsePremiums,
  LookupWording,
  LookupInterestedParty,
  LookupCities
} from '@/api';
import { LookupState } from '../states';

function processLookup<
  T extends
    | LookupGeneral
    | LookupRegion
    | LookupCities
    | LookupSector
    | LookupTrade
    | LookupLossOfUsePremiums
    | LookupWording
    | LookupInterestedParty
>(
  array: T[],
  labelKey: keyof T,
  valueKey: keyof T = labelKey,
  all = false
): {
  text: T[keyof T];
  value: T[keyof T];
}[] {
  return array.map(lookup => {
    return { text: lookup[labelKey], value: lookup[valueKey], ...(all ? lookup : {}) };
  });
}

const GeneralReturnTypeWrapper = () =>
  processLookup([] as LookupGeneral[], 'label');
export type GeneralLookupOption = ReturnType<
  typeof GeneralReturnTypeWrapper
>[number];
const RegionReturnTypeWrapper = () =>
  processLookup([] as LookupRegion[], 'region');
export type RegionLookupOption = ReturnType<
  typeof RegionReturnTypeWrapper
>[number];
const CitiesReturnTypeWrapper = () =>
  processLookup([] as LookupCities[], 'name');
export type CitiesLookupOption = ReturnType<
  typeof CitiesReturnTypeWrapper
>[number];
const SectorReturnTypeWrapper = () =>
  processLookup([] as LookupSector[], 'name');
export type SectorLookupOption = ReturnType<
  typeof SectorReturnTypeWrapper
>[number];
const LossOfUsePremiumsReturnTypeWrapper = () =>
  processLookup([] as LookupLossOfUsePremiums[], 'label');
export type LossOfUsePremiumsLookupOption = ReturnType<
  typeof LossOfUsePremiumsReturnTypeWrapper
>[number];
const TradeReturnTypeWrapper = () => processLookup([] as LookupTrade[], 'name');
export type TradeLookupOption = ReturnType<
  typeof TradeReturnTypeWrapper
>[number];
const WordingReturnTypeWrapper = () =>
  processLookup([] as LookupTrade[], 'name');
export type WordingLookupOption = ReturnType<
  typeof WordingReturnTypeWrapper
>[number];
const InterestedPartyReturnTypeWrapper = () =>
  processLookup([] as LookupInterestedParty[], 'name');
export type InterestedPartyLookupOption = ReturnType<
  typeof InterestedPartyReturnTypeWrapper
>[number];

export const general = (state: LookupState) => (
  type: string,
  subtype?: string
): GeneralLookupOption[] => {
  const lookup =
    subtype === undefined
      ? state.general['']?.[type]
      : state.general[type]?.[subtype];
  return lookup !== undefined ? processLookup(lookup, 'label', 'value') : [];
};
export const interestedParty = (state: LookupState) => (
  type: string,
): InterestedPartyLookupOption[] => {
  return state.interestedParty !== undefined
    ? processLookup(state.interestedParty, 'name',)
    : [];
};
export const region = (state: LookupState): RegionLookupOption[] => {
  return state.region !== undefined
    ? processLookup(state.region, 'region')
    : [];
};
export const cities = (state: LookupState): CitiesLookupOption[] => {
  return state.cities !== undefined
    ? processLookup(state.cities, 'name')
    : [];
};
export const sector = (state: LookupState): SectorLookupOption[] => {
  return state.sector !== undefined ? processLookup(state.sector, 'name') : [];
};
export const trade = (state: LookupState): TradeLookupOption[] => {
  return state.trade !== undefined ? processLookup(state.trade, 'name') : [];
};
export const lossOfUsePremiums = (state: LookupState) => (
  column: keyof LookupLossOfUsePremiums,
): LossOfUsePremiumsLookupOption[] => {
  // Return all if undefined
  const lookups =  state.lossOfUsePremiums !== undefined ? processLookup(state.lossOfUsePremiums, column, column, true) : [];
  switch(column) {
    case 'lossOfUseLength': {
      for (const lookup of lookups) {
        lookup.text = `Maximum ${lookup.value}, Consecutive`
      }
      break;
    }
    case 'maximumAmountPerDay':
    case 'premium':
    default: {
      for (const lookup of lookups) {
        lookup.text = `$${lookup.text}`
      }
      break;
    }
  }
  return lookups; 
};
export const wording = (
  state: LookupState
): ((type: string) => WordingLookupOption[]) => (
  type: string
): WordingLookupOption[] => {
  const lookup = state.wording[type];
  return lookup !== undefined
    ? processLookup(lookup, 'wording_name', 'wording_url')
    : [];
};

type StringReturnType = { text: string; value: string }[];

export const months = (): StringReturnType => {
  return [
    { text: 'January', value: '1' },
    { text: 'February', value: '2' },
    { text: 'March', value: '3' },
    { text: 'April', value: '4' },
    { text: 'May', value: '5' },
    { text: 'June', value: '6' },
    { text: 'July', value: '7' },
    { text: 'August', value: '8' },
    { text: 'September', value: '9' },
    { text: 'October', value: '10' },
    { text: 'November', value: '11' },
    { text: 'December', value: '12' }
  ];
};

type yearsLookupType = (options: {
  range: number;
  from: number;
}) => StringReturnType;
export const years = (): yearsLookupType => ({
  range,
  from
}: {
  range?: number;
  from?: number;
}): StringReturnType => {
  const options: StringReturnType = [];

  const thisYear = new Date().getFullYear();
  let y: number;
  if (from !== undefined) {
    y = from;
  } else if (range !== undefined) {
    y = new Date().getFullYear() - range;
  } else {
    return options;
  }

  for (y; y <= thisYear; y++) {
    const year = y.toString();
    options.push({ text: year, value: year });
  }

  return options.reverse();
};
