























































import Vue from '@/vue';
import Component from 'vue-class-component';
import { required } from 'vuelidate/lib/validators';

@Component
export default class BiPersonItem extends Vue.extend({
  validations: {
    value: {
      fullName: {
        required,
      },
      experience: {
        required,
      },
      // files: { }
    },
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return {
          fullName: '',
          experience: '',
          // files: [],
        };
      },
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
}) {
  touch(): void {
    this.$v.value.$touch();
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }
}
