import Vue from '@/vue';

export default function(): void {
  // Config for form alignment / input sizes
  const formOptions = {
    labelAlign: 'left',
    labelAlignLg: 'right',
    labelCols: 4,
    formCols: 12,
    inputCols: 0,
    formView: {
      side: 2,
      sideLg: 1,
      center: 0,
      centerLg: 0
    }
  };

  formOptions.inputCols = 12 - formOptions.labelCols * 2;
  formOptions.formView.center = 12 - formOptions.formView.side * 2;
  formOptions.formView.centerLg = 12 - formOptions.formView.sideLg * 2;

  Vue.prototype.$formOptions = formOptions;
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $formOptions: {
      labelAlign: string;
      labelAlignLg: string;
      labelCols: number;
      formCols: number;
      inputCols: number;
      formView: {
        side: number;
        sideLg: number;
        center: number;
        centerLg: number;
      };
    };
  }
}
