
























































































































































import { PackageForDashboard, PolicyInsurerForDashboard } from '@/api';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class PolicyQuoteView extends Vue.extend({
  props: {
    data: {
      type: Object as PropType<PackageForDashboard>,
      required: true,
    },
    showPromoCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPolicyNumbers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  get isRenewal(): boolean {
    return this.data.isRenewal || false;
  }

  get bliSelected(): boolean {
    return (
      this.data.policies?.liability?.liability?.id !== undefined &&
      this.data.policies.liability.liability.id !== null &&
      this.data.policies.liability.isRenewal === true
    );
  }
  get bliVisible(): boolean {
    return this.data.policies?.liability?.isVisible === true;
  }
  get bliTitle(): string {
    if (
      this.bliInsured &&
      (this.showPolicyNumbers || this.data.policies?.liability?.isRenewal)
    ) {
      if (this.data.policies?.liability?.liability?.id !== undefined) {
        let policyNumber = this.data.policies?.liability?.refNumber;
        const policyNumberText =
          policyNumber === undefined ? 'New' : `Policy Number ${policyNumber}`;

        return `Liability - ${policyNumberText}`;
      }
    }
    return 'Liability';
  }
  get bliInsured(): boolean {
    return this.data.policies?.liability?.publicGeneral?.isInsured || false;
  }
  get bliInsurer(): PolicyInsurerForDashboard {
    return this.data.policies?.liability?.insurer || {};
  }
  get bliReferred(): boolean {
    return (
      this.data.policies?.liability?.liability?.refer === true ||
      this.data.policies?.liability?.publicGeneral?.refer === true ||
      this.data.policies?.liability?.statutory?.refer === true ||
      this.data.policies?.liability?.employers?.refer === true ||
      this.data.policies?.liability?.directorsAndOfficers?.refer === true ||
      this.data.policies?.liability?.employeeDisputes?.refer === true ||
      this.data.policies?.liability?.crimeFidelity?.refer === true ||
      this.data.policies?.liability?.legalProsecutionDefenceCosts?.refer ===
        true ||
      false
    );
  }
  get bliPremium(): number | undefined {
    return this.data.policies?.liability?.subTotalNett || undefined;
  }
  get bliPaid(): boolean {
    return this.data.policies?.liability?.isPayable === false || false;
  }

  get mdtSelected(): boolean {
    return (
      this.data.policies?.toolsAndEquipment?.toolsAndEquipment?.id !==
        undefined &&
      this.data.policies.toolsAndEquipment.toolsAndEquipment.id !== null &&
      this.data.policies.toolsAndEquipment.isRenewal === true
    );
  }
  get mdtVisible(): boolean {
    return this.data.policies?.toolsAndEquipment?.isVisible === true;
  }
  get mdtTitle(): string {
    if (
      this.mdtInsured &&
      (this.showPolicyNumbers ||
        this.data.policies?.toolsAndEquipment?.isRenewal)
    ) {
      if (
        this.data.policies?.toolsAndEquipment?.toolsAndEquipment?.id !==
        undefined
      ) {
        let policyNumber = this.data.policies?.toolsAndEquipment?.refNumber;
        const policyNumberText =
          policyNumber === undefined ? 'New' : `Policy Number ${policyNumber}`;

        return `Tools & Equipment - ${policyNumberText}`;
      }
    }
    return 'Tools & Equipment';
  }
  get mdtInsured(): boolean {
    return (
      this.data.policies?.toolsAndEquipment?.toolsAndEquipment?.isInsured ||
      false
    );
  }
  get mdtInsurer(): PolicyInsurerForDashboard {
    return this.data.policies?.toolsAndEquipment?.insurer || {};
  }
  get mdtReferred(): boolean {
    return (
      this.data.policies?.toolsAndEquipment?.toolsAndEquipment?.refer ===
        true || false
    );
  }
  get mdtPremium(): number | undefined {
    return this.data.policies?.toolsAndEquipment?.subTotalNett || undefined;
  }
  get mdtPaid(): boolean {
    return this.data.policies?.toolsAndEquipment?.isPayable === false || false;
  }

  get cmvSelected(): boolean {
    return (
      this.data.policies?.commercialVehicles?.commercialVehicles?.id !==
        undefined &&
      this.data.policies.commercialVehicles.commercialVehicles.id !== null &&
      this.data.policies.commercialVehicles.isRenewal === true
    );
  }
  get cmvVisible(): boolean {
    return this.data.policies?.commercialVehicles?.isVisible === true;
  }
  get cmvTitle(): string {
    if (
      this.cmvInsured &&
      (this.showPolicyNumbers ||
        this.data.policies?.commercialVehicles?.isRenewal)
    ) {
      if (
        this.data.policies?.commercialVehicles?.commercialVehicles?.id !==
        undefined
      ) {
        let policyNumber = this.data.policies?.commercialVehicles?.refNumber;
        const policyNumberText =
          policyNumber === undefined ? 'New' : `Policy Number ${policyNumber}`;

        return `Commercial Vehicles - ${policyNumberText}`;
      }
    }
    return 'Commercial Vehicles';
  }
  get cmvInsured(): boolean {
    return (
      this.data.policies?.commercialVehicles?.vehicles?.findIndex(
        (v) => v.isInsured === true
      ) !== -1
    );
  }
  get cmvInsurer(): PolicyInsurerForDashboard {
    return this.data.policies?.commercialVehicles?.insurer || {};
  }
  get cmvReferred(): boolean {
    return (
      this.data.policies?.commercialVehicles?.commercialVehicles?.refer ===
        true || false
    );
  }
  get cmvPremium(): number | undefined {
    return this.data.policies?.commercialVehicles?.subTotalNett || undefined;
  }
  get cmvPaid(): boolean {
    return this.data.policies?.commercialVehicles?.isPayable === false || false;
  }

  get selectedPolicies(): string[] {
    const policyCodes: string[] = [];

    if (this.bliSelected) {
      policyCodes.push('bli');
    }
    if (this.mdtSelected) {
      policyCodes.push('mdt');
    }
    if (this.cmvSelected) {
      policyCodes.push('cmv');
    }

    return policyCodes;
  }

  get hiddenPolicies(): string[] {
    const policyCodes: string[] = [];

    if (!this.bliVisible) {
      policyCodes.push('bli');
    }
    if (!this.mdtVisible) {
      policyCodes.push('mdt');
    }
    if (!this.cmvVisible) {
      policyCodes.push('cmv');
    }

    return policyCodes;
  }
}
