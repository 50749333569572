import { NavigationGuard } from 'vue-router';
import { PackageAnswersApi } from "@/api";

export const preventDirectFormNavigation: NavigationGuard = async (
  to,
  from,
  next
): Promise<void> => {
  // NOTE: Using the `name` property is unreliable because the default value of `null` for external locations could
  // change, and we may forget to define a route name somewhere

  // Ignore non form routes
  if (!to.path.includes('/form/')) {
    return next();
  }

  // Ignore navigation from internal routes
  if (!['/', null, undefined].includes(from.path)) {
    return next();
  }

  // Get form name and page number from URL eg `.../form/(liability)/(1)/...`
  const [, formName, formPage] = to.path.match(/\/form\/(.+?)\/(\d+?)\/?.*$/) ?? [];

  // Ignore form page 1
  if (formPage === '1') {
    return next();
  }

  const policyCode: string | undefined = {
    'liability': 'BLI',
    'tools': 'MDT',
    'commercial-vehicles': 'CMV',
  }[formName];

  // This shouldn't be hit in practice as vue router should not match this route without a package hash
  if (to.params.packageHash === undefined) {
    return next('/');
  }

  const getFormUrl = (form: string, page: string|number) => `/quote/${to.params.packageHash}/form/${form}/${page}`


  // If missing form name mapping, handle by continuing as normal
  if (policyCode === undefined) {
    return next(getFormUrl(formName, formPage));
  }

  const answers = await new PackageAnswersApi()
      .getPackagePolicyGroupAnswers(to.params.packageHash, policyCode)

  // Send to page 1 if form has not yet been completed
  if (answers.data.data?.answers?.id === undefined || answers.data.data.answers.id === null) {
    return next({ path: getFormUrl(formName, 1), replace: true })
  }

  // Continue if form has already been completed (policy exists)
  return next();
};
