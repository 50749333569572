import { LookupGeneral, LookupInterestedParty } from '@/api';
import Vue from '@/vue';
import { LookupState } from '../states';

export const general = (
  state: LookupState,
  {
    type,
    subtype,
    lookup
  }: {
    type: string;
    subtype?: string;
    lookup?: LookupGeneral[];
  }
): void => {
  if (lookup !== undefined) {
    if (subtype === undefined) {
      if (state.general[''] === undefined) {
        Vue.set(state.general, '', {});
      }
      Vue.set(state.general[''], type, lookup);
    } else {
      if (state.general[type] === undefined) {
        Vue.set(state.general, type, {});
      }
      Vue.set(state.general[type], subtype, lookup);
    }
  }
};

export const region = (
  state: LookupState,
  {
    lookup
  }: {
    lookup?: LookupGeneral;
  }
): void => {
  if (lookup !== undefined) {
    Vue.set(state, 'region', lookup);
  }
};
export const cities = (
  state: LookupState,
  {
    lookup
  }: {
    lookup?: LookupGeneral;
  }
): void => {
  if (lookup !== undefined) {
    Vue.set(state, 'cities', lookup);
  }
};
export const sector = (
  state: LookupState,
  {
    lookup
  }: {
    lookup?: LookupGeneral;
  }
): void => {
  if (lookup !== undefined) {
    Vue.set(state, 'sector', lookup);
  }
};
export const trade = (
  state: LookupState,
  {
    lookup
  }: {
    lookup?: LookupGeneral;
  }
): void => {
  if (lookup !== undefined) {
    Vue.set(state, 'trade', lookup);
  }
};

export const lossOfUsePremiums = (
  state: LookupState,
  {
    lookup
  }: {
    lookup?: LookupGeneral;
  }
): void => {
  if (lookup !== undefined) {
    Vue.set(state, 'lossOfUsePremiums', lookup);
  }
};
export const wording = (
  state: LookupState,
  {
    type,
    lookup
  }: {
    type: string;
    lookup?: LookupGeneral;
  }
): void => {
  if (lookup !== undefined) {
    Vue.set(state.wording, type, lookup);
  }
};
export const interestedParty = (
  state: LookupState,
  {
    lookup
  }: {
    lookup?: LookupInterestedParty;
  }
): void => {
  if (lookup !== undefined) {
    Vue.set(state, 'interestedParty', lookup);
  }
};
