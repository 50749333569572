import {
  AnswersBli,
  AnswersMdt,
  AnswersCmv,
  PackageForDashboardPoliciesLiability,
  PackageForDashboardPoliciesToolsAndEquipment,
  PackageForDashboardPoliciesCommercialVehicles,
  PackageForDashboard,
  LookupGeneral,
  LookupRegion,
  LookupCities,
  LookupSector,
  LookupTrade,
  LookupWording,
  LookupLossOfUsePremiums,
  LookupInterestedParty
} from '../api';

export enum LiabilityPolicyType {
  PublicGeneral = 'publicGeneral',
  Statutory = 'statutory',
  Employers = 'employers',
  DirectorsAndOfficers = 'directorsAndOfficers',
  EmployeeDisputes = 'employeeDisputes',
  CrimeFidelity = 'crimeFidelity',
  LegalProsecutionDefenceCosts = 'legalProsecutionDefenceCosts'
}

export interface PolicyState {
  policy:
    | PackageForDashboardPoliciesLiability
    | PackageForDashboardPoliciesToolsAndEquipment
    | PackageForDashboardPoliciesCommercialVehicles;
  answers: AnswersBli | AnswersMdt | AnswersCmv;
}

export interface BLIState extends PolicyState {
  policy: PackageForDashboardPoliciesLiability;
  answers: AnswersBli;
}
export interface MDTState extends PolicyState {
  policy: PackageForDashboardPoliciesToolsAndEquipment;
  answers: AnswersMdt;
}
export interface CMVState extends PolicyState {
  policy: PackageForDashboardPoliciesCommercialVehicles;
  answers: AnswersCmv;
}

export interface LookupState {
  general: {
    [type: string]: {
      [subtype: string]: LookupGeneral[] | undefined;
    };
  };
  region?: LookupRegion[];
  cities?: LookupCities[];
  sector?: LookupSector[];
  trade?: LookupTrade[];
  lossOfUsePremiums?: LookupLossOfUsePremiums[];
  wording: {
    [type: string]: LookupWording[] | undefined;
  };
  interestedParty?: LookupInterestedParty[];
}

export interface Global {
  emailAsked: boolean;
}

export interface State {
  global: Global;
  package: PackageForDashboard;
  liability: BLIState;
  toolsAndEquipment: MDTState;
  commercialVehicles: CMVState;
  lookups: LookupState;
}
