import Vue from '@/vue';
import { Global as State } from '..';

export const setEmailAsked = (
  state: State,
  { emailAsked }: { emailAsked: boolean }
): void => {
  Vue.set(state, 'emailAsked', emailAsked);
};

export const reset = (state: State): void => {
  Vue.set(state, 'emailAsked', false);
};
