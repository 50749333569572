




































import Vue from '@/vue';
import { BModal } from 'bootstrap-vue';

export default class BiModalPackageNotPayable extends Vue.extend() {
  show(): void {
    (this.$refs.modal as BModal).show();
  }

  hide(): void {
    (this.$refs.modal as BModal).hide();
  }

  cancel(): void {
    this.$emit('cancel');
    this.hide();
  }
}
