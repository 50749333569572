import { LookupApi } from '@/api';
import { ActionContext } from 'vuex';
import { LookupState, State } from '../states';

export const getGeneralLookup = async (
  { state, commit }: ActionContext<LookupState, State>,
  {
    type,
    subtype
  }: {
    type: string;
    subtype?: string;
  }
): Promise<void> => {
  let noLookup = state.general?.[type] === undefined;
  if (!noLookup && subtype !== undefined) {
    noLookup = state.general[type][subtype] === undefined;
  }
  if (noLookup) {
    try {
      const {
        data: { data }
      } = await new LookupApi().getGeneralLookups(type, subtype);
      if (data !== undefined) {
        commit('general', { type, subtype, lookup: data });
      } else {
        throw new Error(`General Lookup ${type}.${subtype} does not exist`);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getInterestedPartyLookup = async (
  { state, commit }: ActionContext<LookupState, State>,
  {
    type
  }: {
    type: string;
  }
): Promise<void> => {
  if (state.interestedParty === undefined) {
    try {
      const {
        data: { data }
      } = await new LookupApi().getInterestedPartyLookup(type);
      if (data !== undefined) {
        commit('interestedParty', { type, lookup: data });
      } else {
        throw new Error(`Interested Party Lookup ${type} does not exist`);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getRegionLookup = async ({
  state,
  commit
}: ActionContext<LookupState, State>): Promise<void> => {
  if (state.region === undefined) {
    try {
      const {
        data: { data }
      } = await new LookupApi().getRegionLookups();
      if (data !== undefined) {
        commit('region', { lookup: data });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getCitiesLookup = async ({
  state,
  commit
}: ActionContext<LookupState, State>): Promise<void> => {
  if (state.cities === undefined) {
    try {
      const {
        data: { data }
      } = await new LookupApi().getCitiesLookups();
      if (data !== undefined) {
        commit('cities', { lookup: data });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getSectorLookup = async ({
  state,
  commit
}: ActionContext<LookupState, State>): Promise<void> => {
  if (state.sector === undefined) {
    try {
      const {
        data: { data }
      } = await new LookupApi().getSectorLookups();
      if (data !== undefined) {
        commit('sector', { lookup: data });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getTradeLookup = async ({
  state,
  commit
}: ActionContext<LookupState, State>): Promise<void> => {
  if (state.trade === undefined) {
    try {
      const {
        data: { data }
      } = await new LookupApi().getTradeLookups();
      if (data !== undefined) {
        commit('trade', { lookup: data });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getLossOfUsePremiumLookup = async ({ state, commit }: ActionContext<LookupState, State>,
  {
    column
  }: {
    column?: string;
  }
): Promise<void> => {
  if (state.lossOfUsePremiums === undefined) {
    try {
      const {
        data: { data }
      } = await new LookupApi().getLossOfUsePremiumLookup(column);
      if (data !== undefined) {
        commit('lossOfUsePremiums', { lookup: data });
      }
    } catch (e) {
      console.error(e);
    }
  }
}