









































import Vue from '@/vue';
import { PropType } from 'vue';
export default class BiYesNo extends Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: null,
    },
    reverseOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    yesLabel: {
      type: String,
      required: false,
      default: 'Yes',
    },
    noLabel: {
      type: String,
      required: false,
      default: 'No',
    },
    variantInactive: {
      type: String,
      required: false,
      default: 'outline-primary',
    },
    variantActive: {
      type: String,
      required: false,
      default: 'primary',
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonClasses: {
      type: Object as PropType<{ [c: string]: boolean }>,
      required: false,
      default: () => {
        return {};
      },
    },
  },
}) {
  input(value: boolean): void {
    this.$emit('input', value);
  }

  get thisValue(): boolean {
    return this.value;
  }

  get computedButtonClasses(): { [c: string]: boolean } {
    const buttonClasses = Object.keys(this.buttonClasses);
    const widthClass = this.inline ? 'w-50' : 'w-100';

    if (buttonClasses.some((c) => /w-[0-9]+/.test(c))) {
      return this.buttonClasses;
    } else {
      return { [widthClass]: true, ...this.buttonClasses };
    }
  }
}
