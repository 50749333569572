

































import Vue from '@/vue';
import Component from 'vue-class-component';

@Component
export default class BiSeparator extends Vue.extend({
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    offsetLeft: {
      type: [String, Number],
      required: false,
      default: '0',
    },
    offsetRight: {
      type: [String, Number],
      required: false,
      default: '0',
    },
    textClasses: {
      type: String,
      required: false,
      default: '',
    },
    dotted: {
      type: Boolean,
      required: false,
      default: false,
    },
    dashed: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: true,
    },
    marginBottom: {
      type: String,
      required: false,
      default: '3',
    },
    marginTop: {
      type: String,
      required: false,
      default: '3',
    },
  },
}) {
  get computedTextClasses(): string {
    return `m-0 ${this.textClasses}`;
  }
  get lineType(): string {
    if (this.dotted) {
      return 'dotted';
    } else if (this.dashed) {
      return 'dashed';
    } else {
      return 'solid';
    }
  }
}
