import { AnswersMdt } from '@/api';
import Vue from '@/vue';
import { MDTState } from '../states';

export const isInsured = (
  state: MDTState,
  {
    isInsured: _isInsured = state.policy.toolsAndEquipment?.isInsured
  }: {
    isInsured: boolean | null | undefined;
  }
): void => {
  if (state.policy.toolsAndEquipment !== undefined) {
    Vue.set(state.policy.toolsAndEquipment, 'isInsured', _isInsured);
  }
};

export const answers = (
  state: MDTState,
  {
    id = undefined,
    toolValue = undefined,
    scaffoldingValue = undefined,
    isMadeClaim = undefined,
    mdtToolEquipmentClaims = undefined,
    specifiedItems = undefined
  }: AnswersMdt
): void => {
  const idNull = id === undefined || id === null;

  if (!idNull) {
    Vue.set(state.answers, 'id', id);
  }

  if (toolValue !== undefined && toolValue !== null) {
    Vue.set(state.answers, 'toolValue', toolValue);
  }
  if (scaffoldingValue !== undefined && scaffoldingValue !== null) {
    Vue.set(state.answers, 'scaffoldingValue', scaffoldingValue);
  }

  if (isMadeClaim !== undefined && isMadeClaim !== null) {
    Vue.set(state.answers, 'isMadeClaim', isMadeClaim);
  }
  if (
    mdtToolEquipmentClaims !== undefined &&
    (!idNull || mdtToolEquipmentClaims.length !== 0)
  ) {
    Vue.set(state.answers, 'mdtToolEquipmentClaims', mdtToolEquipmentClaims);
  }

  if (specifiedItems !== undefined && !idNull) {
    Vue.set(state.answers, 'specifiedItems', specifiedItems);
  }
};

export const reset = (state: MDTState): void => {
  Vue.set(state, 'policy', {
    toolsAndEquipment: {
      id: undefined,
      isInsured: undefined,
      coverOf: undefined,
      premium: undefined,
      refer: undefined
    },
    subTotal: undefined,
    isAvailable: undefined,
    insurerName: undefined,
    insurerFinancialStrengthRating: undefined,
    insurerUrl: undefined,
    insurerText: undefined,
    financeCostPerMonth: undefined,
    isFormCompleted: undefined
  });
  Vue.set(state, 'answers', {
    id: undefined,
    isMadeClaim: undefined,
    toolValue: undefined,
    scaffoldingValue: undefined,
    mdtToolEquipmentClaims: [],
    specifiedItems: []
  });
};
