
































































































import {
  PackageForDashboardPoliciesCommercialVehicles,
  PackagePolicyVehicleApi,
  PolicyVehicleForDashboard,
} from '@/api';
import BiFormView from '@/components/bi-form-view/bi-form-view.vue';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class DashboardPolicyCmvView extends Vue.extend({
  props: {
    data: {
      type: Object as PropType<PackageForDashboardPoliciesCommercialVehicles>,
    },
    complete: {
      type: Boolean,
      required: true,
      default: false,
    },
    showPremium: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
}) {
  get vehicleTypeOfCoverLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general](
      'coverType',
      'cmv'
    );
  }
  get underwriting(): boolean {
    return this.data.commercialVehicles?.status === 'Underwriting-Insurer'
      || this.data.commercialVehicles?.status === 'Underwriting-Client'
      || this.data.commercialVehicles?.status === 'Underwriting-outside scheme'
  }
  get vehicleLossOfUseLookup(): { text: string; value: string } {
    return this.$store.getters[this.$getters.lookups.general](
      'lossOfUse',
      'cmv'
    );
  }
  get vehicleLossOfUseCoverLookup(): { text: string; value: string }[] {
    let lookup: Record<string, string>[] = this.$store.getters[
      this.$getters.lookups.lossOfUsePremiums
    ]('maximumAmountPerDay');
    let maxDays: Record<string, string> = {};
    for (let lookupElement of lookup) {
      maxDays[lookupElement.text] = lookupElement.value;
    }

    let result: { text: string; value: string }[] = [];
    for (let maxDaysKey in maxDays) {
      result.push({
        text: maxDaysKey,
        value: maxDays[maxDaysKey],
      });
    }
    return result;
  }

  get commercialVehiclesRenewing(): boolean {
    return (
      this.data.commercialVehicles?.id !== undefined &&
      this.data.commercialVehicles.id !== null &&
      this.data.isRenewal === true
    );
  }

  get isPaid(): boolean {
    return this.data?.isPayable === false || false;
  }

  get completeFormLabel(): string {
    return this.commercialVehiclesRenewing
      ? 'Confirm details to renew policy'
      : 'Complete form to add policy';
  }

  // Methods
  addVehicle(): void {
    this.$router.push(this.$getFormPath('commercial-vehicles', '5/new'));
  }

  addTrailer(): void {
    this.$router.push(
      this.$getFormPath('commercial-vehicles', '5/new', { isTrailer: '1' })
    );
  }

  editVehicle(index: number): void {
    const id = this.data?.vehicles?.[index].id;
    if (id !== undefined) {
      this.$router.push(this.$getFormPath('commercial-vehicles', `5/${id}`));
    }
  }

  declareVehicleClaims(index: number): void {
    const id = this.data?.vehicles?.[index].id;
    if (id !== undefined) {
      this.$router.push(this.$getFormPath('commercial-vehicles', `6/${id}`));
    }
  }

  deleteVehicle(index: number): void {
    const vehicle = this.data?.vehicles?.[index];
    if (
      vehicle !== undefined &&
      vehicle.id !== undefined &&
      vehicle.id !== null
    ) {
      new PackagePolicyVehicleApi()
        .deletePackagePolicyVehicle(this.$packageHash, 'CMV', vehicle.id)
        .then(() => {
          this.$emit('delete', vehicle);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  saving: { [key: number]: boolean } = {};
  async saveVehicle(vehicle: PolicyVehicleForDashboard): Promise<void> {
    const id =
      vehicle.id !== undefined && vehicle.id !== null ? vehicle.id : -1;
    this.$set(this.saving, id, true);
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(
          this.$actions.commercialVehicles.saveVehicle,
          {
            hash: this.$packageHash,
            vehicle,
            dashboard: true,
          }
        )
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.$set(this.saving, id, false);
    }
  }

  open(): void {
    (this.$refs.formView as BiFormView).open();
  }

  async save(): Promise<void[]> {
    const promises = [];
    if (this.data.vehicles !== undefined) {
      for (const vehicle of this.data.vehicles) {
        promises.push(this.saveVehicle(vehicle));
      }
    }
    return Promise.all(promises);
  }
}
