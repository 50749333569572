














































































































































































import { PolicyForDashboard, PolicyLiabilityForDashboard } from '@/api';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class BiFormViewItem extends Vue.extend({
  props: {
    value: {
      type: Object as PropType<
        PolicyForDashboard | PolicyLiabilityForDashboard
      >,
      required: false,
      default: () => {
        return {
          isInsured: true,
          coverOf: '',
        } as PolicyForDashboard | PolicyLiabilityForDashboard;
      },
    },
    title: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: false,
      default: 'Cover of',
    },
    'info-link': {
      type: String,
      required: false,
      default: '',
    },
    tooltip: {
      type: Object as PropType<{ title: string; content: string }>,
      required: false,
      default: () => {
        return { title: '', content: '' };
      },
    },
    options: {
      type: Array,
      required: false,
      default: (): { value: string | number; text: string }[] => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputType: {
      type: String,
      required: false,
      default: 'input',
    },
    showPremium: {
      type: Boolean,
      required: false,
      default: false,
    },
    premium: {
      type: [String, Number],
      required: false,
    },
    isPaid: {
      type: Boolean,
      required: false,
      default: false,
    },
    referred: {
      type: Boolean,
      required: false,
      default: false,
    },
    minValue: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
}) {
  get hasTooltipTitle(): boolean {
    return !!this.tooltip.title || !!this.$slots['tooltip-title'];
  }
  get hasTooltipContent(): boolean {
    return !!this.tooltip.content || !!this.$slots['tooltip-content'];
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }
  emitCoverBlur(): void {
    if (
        this.minValue !== undefined &&
        this.value?.coverOf !== undefined &&
        this.value.coverOf !== null &&
        parseFloat(this.value.coverOf) < this.minValue
    ) {
      this.value.coverOf = this.minValue.toFixed(2)
    }

    this.$emit('cover-blur', this.value);
  }
  emitInsuredChanged(): void {
    this.$emit('insured', this.value);
  }
}
