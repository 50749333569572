






























































import { PolicySpecifiedItem } from '@/api';
import Vue from '@/vue';
import Component from 'vue-class-component';
import { Validations } from 'vuelidate-property-decorators';
import { required, maxValue } from 'vuelidate/lib/validators';

@Component
export default class BiItemItem extends Vue.extend({
  props: {
    value: {
      type: Object,
      required: false,
      default: (): PolicySpecifiedItem => {
        return {
          item: undefined,
          itemValue: undefined,
        };
      },
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    maxItemValue: {
      type: Number,
      required: false,
      default: 1000000,
    },
  },
}) {
  @Validations()
  validations = {
    value: {
      item: { required },
      itemValue: {
        required,
        maxValue: maxValue(this.maxItemValue - 0.01),
      },
    },
  };

  touch(): void {
    this.$v.value.$touch();
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }
}
