






















































import { version } from '../../../package.json';
import { PackageForDashboard } from '@/api';
import Vue from '@/vue';
import Component from 'vue-class-component';

@Component
export default class BiDevMenu extends Vue {
  hideDev = false;

  get routes(): Array<string> {
    const routes = this.$router.getRoutes();
    return routes
      .map((r) => {
        const path = r.path;
        if (path.startsWith('/')) {
          const s = path.split('/');
          if (s.length === 0 || path === '') {
            return '/';
          } else {
            return path;
          }
        }
        return '';
      })
      .filter((r) => r !== '')
      .sort();
  }

  get testingMode(): boolean {
    return this.$testingMode.enabled;
  }

  set testingMode(value: boolean) {
    this.$set(this.$testingMode, 'enabled', value);
  }

  get version(): string {
    return version;
  }
}
