import Vue from '@/vue';

export default function(): void {
  // Limit before extra Tools and Equipment questions are asked
  Vue.prototype.$toolValueLimit = 50000;
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $toolValueLimit: number;
  }
}
