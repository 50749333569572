import Vue from '@/vue';

export default function(): void {
  // Builtin icons
  Vue.prototype.$icons = {
    get liability(): Array<string> {
      return [
        require(`@/assets/icons/Liability_Selected.svg`),
        require(`@/assets/icons/Liability_Unselected.svg`)
      ];
    },
    get toolsAndEquipment(): Array<string> {
      return [
        require(`@/assets/icons/Tools & Equip_Selected.svg`),
        require(`@/assets/icons/Tools & Equip_Unselected.svg`)
      ];
    },
    get commercialVehicles(): Array<string> {
      return [
        require(`@/assets/icons/Vehicle_Selected.svg`),
        require(`@/assets/icons/Vehicle_Unselected.svg`)
      ];
    },
  };
}

// Inject custom instance property types
declare module 'vue/types/vue' {
  interface Vue {
    $icons: {
      liability: Array<string>;
      toolsAndEquipment: Array<string>;
      commercialVehicles: Array<string>;
    };
  }
}
