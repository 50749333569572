import { PolicyVehicle } from '@/api';
import { CMVState } from '../states';

export const complete = (state: CMVState): boolean => {
  return state.answers.id !== undefined && state.answers.id !== null;
};

export const vehicleComplete = () => (vehicle: PolicyVehicle): boolean => {
  const make = vehicle.make !== undefined && vehicle.make.length > 0;
  const model = vehicle.model !== undefined && vehicle.model.length > 0;
  const year = vehicle.year !== undefined && vehicle.year.length > 4;
  const registration =
    vehicle.registration !== undefined && vehicle.registration.length > 0;
  const heavyvehicle = vehicle.heavyvehicle !== undefined;
  const marketvalue =
    vehicle.marketvalue !== undefined && vehicle.marketvalue > 0;

  const involvedInClaim = vehicle.involvedInClaim !== undefined;

  let claims;

  if (involvedInClaim && vehicle.involvedInClaim) {
    const claimsList = vehicle.cmvPolicyVehicleClaims;
    claims = !(claimsList === undefined || claimsList.length === 0);
  } else {
    claims = true;
  }

  const hasInterestedParties = vehicle.hasInterestedParties !== undefined;
  const interestedPartiesDetails =
    vehicle.interestedPartiesDetails !== undefined;
  const interestedPartiesDetailsOther =
    vehicle.interestedPartiesDetails?.toLowerCase() !== 'other' ||
    (vehicle.interestedPartiesDetailsOther !== undefined &&
      vehicle.interestedPartiesDetailsOther.length > 0);

  return (
    make &&
    model &&
    year &&
    registration &&
    heavyvehicle &&
    marketvalue &&
    involvedInClaim &&
    claims &&
    hasInterestedParties &&
    interestedPartiesDetails &&
    interestedPartiesDetailsOther
  );
};
