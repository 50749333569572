


































































import Vue from '@/vue';
import Component from 'vue-class-component';

@Component
export default class BiFormSuccess extends Vue.extend({
  props: {
    size: {
      type: Number,
      required: false,
      default: 7.5,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: 'check',
    },
    thumb: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconScale: {
      type: [String, Number],
      required: false,
      default: 1,
    },
    subtitleMultiLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  get iconComponent(): string {
    return `b-icon-${this.icon}`;
  }

  public animate(): void {
    (this.$refs.bg as HTMLElement).style.animationPlayState = 'running';
    (this.$refs.fg as HTMLElement).style.animationPlayState = 'running';
    if (!this.thumb) {
      (this.$refs.icon as HTMLElement).style.animationPlayState = 'running';
    }
  }
}
