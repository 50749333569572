












































































































































































































import { PackageForDashboardPoliciesLiability } from '@/api';
import BiFormView from '@/components/bi-form-view/bi-form-view.vue';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class DashboardPolicyBliView extends Vue.extend({
  props: {
    data: {
      type: Object as PropType<PackageForDashboardPoliciesLiability>,
    },
    complete: {
      type: Boolean,
      required: true,
      default: false,
    },
    showPremium: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
}) {
  enabledPolicies: string[] = [
    'gl',
    'sl',
    'el',
    'do',
    'ed',
    'crime',
    'lpdc',
  ];
  policyWording = {
    publicGeneral: {
      text: "Covers your liability for accidental damage or loss of use of another person's property.\nIncludes cover for damage caused by faulty workmanship. Includes associated legal defense costs.",
      link: 'https://builtininsurance.co.nz/policies/public-liability/',
    },
    statutory: {
      text: 'Covers fines and penalties and pays legal defence costs due to unintentional breaches of legislation. These include the Building Act, Resource Management Act and Fair Trading Act. Additionally, the policy pays defence costs and reparations following a Health and Safety prosecution (but not the fine).',
      link: 'https://builtininsurance.co.nz/policies/statutory-liability/',
    },
    employers: {
      text: "Defends you against civil action by employees alleging workplace illness or injury that isn't covered by ACC.",
      link: 'https://builtininsurance.co.nz/policies/employers-liability/',
    },
    directorsAndOfficers: {
      text: 'Protects directors and officers from liability for allegations of mismanagement of the company, including legal defence costs.',
      link: 'https://builtininsurance.co.nz/policies/directors-officers-liability/',
    },
    employeeDisputes: {
      text: 'Provides protection from employment disputes, such as allegations of unfair dismissal, workplace harassment or discrimination. The policy covers legal costs and any damages awarded.',
      link: 'https://builtininsurance.co.nz/policies/additional-liability/#ed',
    },
    crimeFidelity: {
      text: 'Protects the business against financial loss due to the fraudulent or dishonest acts of its employees or other third parties.',
      link: 'https://builtininsurance.co.nz/policies/additional-liability/#crime',
    },
    legalProsecutionDefenceCosts: {
      text: 'Covers the legal costs defending a criminal prosecution against directors, managers and employees for acts committed whilst going about their daily work.',
      link: 'https://builtininsurance.co.nz/policies/additional-liability/#lp',
    },
  };
  get publicGeneralCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'gl');
  }
  get statutoryCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'sl');
  }
  get employersCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'el');
  }
  get directorsAndOfficersCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'do');
  }
  get employeeDisputesCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'ed');
  }
  get crimeFidelityCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'cri');
  }
  get legalProsecutionDefenceCostsCoverOfLookup(): {
    text: string;
    value: string;
  }[] {
    return this.$store.getters[this.$getters.lookups.general]('cover', 'lpdc');
  }

  get liabilityRenewing(): boolean {
    return (
      this.data.liability?.id !== undefined &&
      this.data.liability.id !== null &&
      this.data.isRenewal === true
    );
  }

  get isPaid(): boolean {
    return this.data?.isPayable === false || false;
  }

  get underwriting(): boolean {
    return this.data.liability?.status === 'Underwriting-Insurer'
      || this.data.liability?.status === 'Underwriting-Client'
      || this.data.liability?.status === 'Underwriting-outside scheme'
  }

  get completeFormLabel(): string {
    return this.liabilityRenewing
      ? 'Confirm details to renew policy'
      : 'Complete form to add policy';
  }

  publicGeneralSaving = false;
  async savePublicGeneralLiability(): Promise<void> {
    this.publicGeneralSaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.publicGeneral,
        }),
        'gl'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.publicGeneralSaving = false;
    }
  }
  onLiabilityPublicGeneralChanged(): void {
    if (this.$loading) {
      return;
    }

    this.savePublicGeneralLiability();

    if (
      this.data.publicGeneral?.isInsured === undefined ||
      this.data.publicGeneral.isInsured === false ||
      this.data.publicGeneral.coverOf === undefined ||
      this.data.publicGeneral.coverOf === 'Not Included'
    ) {
      if (this.data.statutory !== undefined) {
        this.data.statutory.isInsured = false;
      }
      if (this.data.employers !== undefined) {
        this.data.employers.isInsured = false;
      }
      if (this.data.directorsAndOfficers !== undefined) {
        this.data.directorsAndOfficers.isInsured = false;
      }
      if (this.data.employeeDisputes !== undefined) {
        this.data.employeeDisputes.isInsured = false;
      }
      if (this.data.crimeFidelity !== undefined) {
        this.data.crimeFidelity.isInsured = false;
      }
      if (this.data.legalProsecutionDefenceCosts !== undefined) {
        this.data.legalProsecutionDefenceCosts.isInsured = false;
      }
    }
  }

  statutorySaving = false;
  async saveStatutoryLiability(): Promise<void> {
    this.statutorySaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.statutory,
        }),
        'sl'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.statutorySaving = false;
    }
  }
  onLiabilityStatutoryChanged(): void {
    if (!this.$loading) {
      this.saveStatutoryLiability();
    }
  }

  employersSaving = false;
  async saveEmployersLiability(): Promise<void> {
    this.employersSaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.employers,
        }),
        'el'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.employersSaving = false;
    }
  }
  onLiabilityEmployersChanged(): void {
    if (!this.$loading) {
      this.saveEmployersLiability();
    }
  }

  directorsAndOfficersSaving = false;
  async saveDirectorsAndOfficersLiability(): Promise<void> {
    this.directorsAndOfficersSaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.directorsAndOfficers,
        }),
        'do'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.directorsAndOfficersSaving = false;
    }
  }
  onLiabilityDirectorsAndOfficersChanged(): void {
    if (!this.$loading) {
      this.saveDirectorsAndOfficersLiability();
    }
  }

  employeeDisputesSaving = false;
  async saveEmployeeDisputesLiability(): Promise<void> {
    this.employeeDisputesSaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.employeeDisputes,
        }),
        'ed'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.employeeDisputesSaving = false;
    }
  }
  onLiabilityEmployeeDisputesChanged(): void {
    if (!this.$loading) {
      this.saveEmployeeDisputesLiability();
    }
  }

  crimeFidelitySaving = false;
  async saveCrimeFidelityLiability(): Promise<void> {
    this.crimeFidelitySaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.crimeFidelity,
        }),
        'crime'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.crimeFidelitySaving = false;
    }
  }
  onLiabilityCrimeFidelityChanged(): void {
    if (!this.$loading) {
      this.saveCrimeFidelityLiability();
    }
  }

  legalProsecutionDefenceCostsSaving = false;
  async saveLegalProsecutionDefenceCostsLiability(): Promise<void> {
    this.legalProsecutionDefenceCostsSaving = true;
    try {
      this.$emit(
        'update',
        await this.$store.dispatch(this.$actions.liability.savePolicy, {
          hash: this.$packageHash,
          policy: this.data.legalProsecutionDefenceCosts,
        }),
        'lpdc'
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.legalProsecutionDefenceCostsSaving = false;
    }
  }
  onLiabilityLegalProsecutionDefenceCostsChanged(): void {
    if (!this.$loading) {
      this.saveLegalProsecutionDefenceCostsLiability();
    }
  }

  open(): void {
    (this.$refs.formView as BiFormView).open();
  }

  async save(): Promise<void[]> {
    return Promise.all([
      this.savePublicGeneralLiability(),
      this.saveStatutoryLiability(),
      this.saveEmployersLiability(),
    ]);
  }
}
