

















































































































import Vue from '@/vue';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { required, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment-timezone';

@Component
export default class BiModalNewPolicyCoverDate extends Vue.extend({
  props: {
    currentStartDate: {
      type: String,
      required: true,
    },
    newPolicyPremium: {
      type: Number,
      required: true,
    },
  },
  validations: {
    anotherDate: {
      required,
    },
    value: {
      required: requiredIf((vm) => {
        return vm.anotherDate === true;
      }),
    },
  },
}) {
  anotherDate: boolean | null = null;
  value = this.currentStartDate;

  get extraDaysCount(): number {
    const newDate = moment(this.value, 'DD/MM/YYYY');
    const oldDate = moment(this.currentStartDate, 'DD/MM/YYYY');

    return oldDate.diff(newDate, 'days');
  }
  get extraDaysPremium(): string {
    if (this.extraDaysCount >= 0) {
      return '0';
    } else {
      return Math.abs(
        (this.newPolicyPremium / 365) * this.extraDaysCount
      ).toFixed(2);
    }
  }
  get extraDaysText(): string {
    const words: string[] = [];
    words.push('For');

    if (this.extraDaysCount > 0) {
      words.push(
        ...[
          'an extra',
          this.extraDaysCount.toString(),
          this.extraDaysCount > 1 ? 'days' : 'day',
        ]
      );
    } else if (this.extraDaysCount === 0) {
      words.push(...['no extra days']);
    } else {
      words.push(
        ...[
          Math.abs(this.extraDaysCount).toString(),
          'less',
          Math.abs(this.extraDaysCount) > 1 ? 'days' : 'day',
        ]
      );
    }
    return words.join(' ');
  }

  show(): void {
    (this.$refs.modal as BModal).show();
  }

  hide(): void {
    (this.$refs.modal as BModal).hide();
  }

  submit(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$emit('submit', this.anotherDate === true ? this.value : undefined);
      this.hide();
    }
  }
  cancel(): void {
    this.$emit('cancel');
    this.hide();
  }
}
