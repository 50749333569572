


























































































































































































































































































































































import { PolicyVehicleForDashboard } from '@/api';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';
import BiSeparator from "@/components/bi-separator.vue";

@Component({
    components: {BiSeparator}
})
export default class BiFormViewVehicle extends Vue.extend({
  props: {
    value: {
      type: Object as PropType<PolicyVehicleForDashboard>,
      required: false,
      default: () => {
        return {
          isInsured: true,
          policyType: '',
          coverOf: '',
        } as PolicyVehicleForDashboard;
      },
    },
    coverOfKey: {
      type: String,
      required: false,
      default: 'coverOf',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    policyOptions: {
      type: Array,
      required: false,
      default: (): { value: string | number; text: string }[] => [],
    },
    lossOfUseOptions: {
      type: Array,
      required: false,
      default: (): { value: string | number; text: string }[] => [],
    },
    lossOfUseCoverOptions: {
      type: Array,
      required: false,
      default: (): { value: string | number; text: string }[] => [],
    },
    showPremium: {
      type: Boolean,
      required: false,
      default: false,
    },
    referred: {
      type: Boolean,
      required: false,
      default: false,
    },
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    deleteOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    noEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  dbTimeouts: {
    [type: string]: {
      handler?: number;
      complete: boolean;
    };
  } = {};

  get title(): string {
    return this.value ? `${this.value.year} ${this.value.make} ${this.value.model}` : 'N/A';
  }

  _debounce(type: string, duration: number, cb: () => void): void {
    if (this.dbTimeouts[type] === undefined) {
      this.dbTimeouts[type] = { handler: undefined, complete: false };
    }

    if (this.dbTimeouts[type].handler !== undefined) {
      clearTimeout(this.dbTimeouts[type].handler);
    }

    if (duration >= 0) {
      this.dbTimeouts[type].handler = setTimeout(() => {
        cb();
        this.dbTimeouts[type].complete = true;
      }, duration);
    } else {
      cb();
      this.dbTimeouts[type].complete = true;
    }
  }

  onChange(): void {
    this._debounce('onChange', this.debounce, () => {
      this.$emit('change', this.value);
    });
  }

  onEdit(): void {
    this.$emit('edit', {});
  }

  onDeclareClaims(): void {
    this.$emit('declare-claims', {});
  }

  onDelete(): void {
    this.$emit('delete', {});
  }
}
