import Vue from '@/vue';

const formatters = {
  maxLength: (max: number) => (value: string) => {
    if (value.length > max) {
      return value.substr(0, max);
    }
    return value;
  },
  maxDecimal: (numbers: number, decimals: number) => (value: string) => {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      return value;
    } else {
      const maxValueString = getMaxValueString(numbers, decimals);

      const maxValue = parseFloat(maxValueString);

      if (numberValue > maxValue) {
        return maxValueString;
      } else {
        const s = value.split('.');
        if (s.length === 2 && decimals <= 0) {
          return s[0];
        } else if (s.length === 2 && s[1].length > decimals) {
          return `${s[0]}.${s[1].substr(0, decimals)}`;
        }
        return value;
      }
    }
  },
  minValue: (min: number) => (value: string) => {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      return value;
    }
    if (numberValue < min) {
      return min.toString();
    }
    return value;
  },
  decimal: (numbers: number, decimals: number, min: number) => (
    value: string
  ) => {
    let val = formatters.maxDecimal(numbers, decimals)(value);
    val = formatters.minValue(min)(val);
    return val;
  }
};

function getMaxValueString(numbers: number, decimals: number): string {
  let maxValueString = '';
  for (let i = 0; i < numbers - decimals; i++) {
    maxValueString += '9';
  }
  if (decimals > 0) {
    maxValueString += '.';
    for (let i = 0; i < decimals; i++) {
      maxValueString += '9';
    }
  }
  return maxValueString;
}

export default function(): void {
  Vue.prototype.$format = formatters;
}

declare module 'vue/types/vue' {
  interface Vue {
    $format: typeof formatters;
  }
}
