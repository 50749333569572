import { PackageForDashboard } from '@/api';

const state: PackageForDashboard = {
  id: undefined,
  hash: undefined,
  status: undefined,
  submitDate: undefined,
  info: {
    firstName: undefined,
    lastName: undefined,
    legalName: undefined,
    email: undefined,
    telephone: undefined,
    otherInfoInsurer: undefined,
    referral: undefined,
    referralOther: undefined,
    postalAddress: undefined,
    postalSuburb: undefined,
    postalCity: undefined,
    postalPostcode: undefined,
    primaryTrades: [],
    secondaryTrades: [],
    otherTrade: undefined,
    areaOfOp: undefined,
    mainSector: undefined,
    estTurnover: undefined,
    principalManual: undefined,
    coverStartDate: undefined,
    tradingStyle: undefined
  },
  promoCode: {
    code: undefined,
    label: undefined,
    discountValue: undefined
  },
  summary: {
    subTotal: undefined,
    gst: undefined,
    total: undefined,
    financeCostPerMonth: undefined
  },
  policies: {
    liability: {
      publicGeneral: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      statutory: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      employers: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      directorsAndOfficers: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      employeeDisputes: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      crimeFidelity: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      legalProsecutionDefenceCosts: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      subTotal: undefined,
      isAvailable: undefined,
      insurer: {
        footnotes: undefined,
        insurerName: undefined,
        moreInfo: undefined,
        rating: undefined,
        text: undefined
      },
      financeCostPerMonth: undefined
    },
    toolsAndEquipment: {
      toolsAndEquipment: {
        id: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      subTotal: undefined,
      isAvailable: undefined,
      insurer: {
        footnotes: undefined,
        insurerName: undefined,
        moreInfo: undefined,
        rating: undefined,
        text: undefined
      },
      financeCostPerMonth: undefined
    },
    commercialVehicles: {
      vehicles: [],
      subTotal: undefined,
      isAvailable: undefined,
      insurer: {
        footnotes: undefined,
        insurerName: undefined,
        moreInfo: undefined,
        rating: undefined,
        text: undefined
      },
      financeCostPerMonth: undefined
    },
  }
};
export default state;
