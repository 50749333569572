import {
  AnswersBli,
  PackageAnswersApi,
  PackageDashboardApi, PackageForDashboard,
  PolicyLiabilityForDashboard,
  PolicyType
} from '@/api';
import { ActionContext } from 'vuex';
import { BLIState, LiabilityPolicyType, State } from '../states';

export const save = async (
  { state, commit }: ActionContext<BLIState, State>,
  {
    hash,
    answers,
    create = false
  }: {
    hash: string;
    answers: AnswersBli;
    create: boolean;
  }
): Promise<void> => {
  commit('answers', answers);
  if (state.answers.id !== undefined && state.answers.id !== null) {
    await new PackageAnswersApi().patchPackagePolicyGroupAnswers(hash, 'BLI', {
      data: { answers }
    });
  } else if (create) {
    const {
      data: response
    } = await new PackageAnswersApi().postPackagePolicyGroupAnswers(
      hash,
      'BLI',
      {
        data: { answers }
      }
    );
    if (response.data !== undefined) {
      commit('answers', response.data.answers);
    }
  }
};

export const savePolicy = async (
  { commit }: ActionContext<BLIState, State>,
  {
    hash,
    policy,
    policyType
  }: {
    hash: string;
    policy: PolicyLiabilityForDashboard;
    policyType: LiabilityPolicyType;
  }
): Promise<PackageForDashboard | undefined> => {
  if (policy.id !== undefined && policy.id !== null) {
    commit('setPolicyLiabilityForDashboard', { policyType, policy });
    const {
      data: response
    } = await new PackageDashboardApi().patchPackageDashboardPolicy(
      hash,
      PolicyType.Bli,
      {
        data: policy
      }
    );
    if (response.data?.policies?.liability?.[policyType] !== undefined) {
      commit('setPolicyLiabilityForDashboard', {
        policyType,
        policy: response.data.policies.liability[policyType]
      });
    }
    return response.data;
  }
};

export const getAnswers = async (
  { commit }: ActionContext<BLIState, State>,
  { hash }: { hash: string }
): Promise<void> => {
  const answers = (
    await new PackageAnswersApi().getPackagePolicyGroupAnswers(hash, 'BLI')
  ).data.data?.answers;
  if (answers?.id !== undefined) {
    commit('answers', answers);
  }
};
