














































































































































import { PolicyClaim } from '@/api';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';
import { required, requiredIf } from 'vuelidate/lib/validators';

@Component
export default class BiClaimItem extends Vue.extend({
  validations: {
    value: {
      whatHappened: { required },
      whatHappenedOther: {
        required: requiredIf((value) => {
          if (value.whatHappened !== undefined) {
            return value.whatHappened?.toLowerCase() === 'other';
          } else {
            return false;
          }
        }),
      },
      claimMonth: { required },
      claimYear: { required },
      claimValue: { required },
    },
  },
  props: {
    value: {
      type: Object as PropType<PolicyClaim>,
      required: false,
      default: (): PolicyClaim => {
        return {
          whatHappened: undefined,
          whatHappenedOther: undefined,
          claimMonth: undefined,
          claimYear: undefined,
          claimValue: undefined,
        };
      },
    },
    eventOptions: {
      type: Array,
      required: false,
      default: (): { value: string | number; text: string }[] => [],
    },
    dateMonthOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    dateYearOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    whatHappenedInputType: {
      type: String,
      required: false,
      default: 'b-select',
    },
    whatHappenedInputOptions: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    showDescriptionField: {
      type: [Boolean, Function] as PropType<
        boolean | ((value: PolicyClaim) => boolean)
      >,
      required: false,
      default: () =>
        function (value: PolicyClaim): boolean {
          return value?.whatHappened?.toLowerCase() === 'other';
        },
    },
  },
}) {
  get cShowDescriptionField(): boolean {
    return typeof this.showDescriptionField === 'boolean'
      ? this.showDescriptionField
      : this.showDescriptionField(this.value);
  }

  touch(): void {
    this.$v.value.$touch();
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }
}
