import { ActionContext } from 'vuex';
import { Global, State } from '../states';

export const reset = ({ commit }: ActionContext<Global, State>): void => {
  commit('global/reset', undefined, { root: true });
  commit('package/reset', undefined, { root: true });
  commit('liability/reset', undefined, { root: true });
  commit('toolsAndEquipment/reset', undefined, { root: true });
  commit('commercialVehicles/reset', undefined, { root: true });
};
