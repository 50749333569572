import { CMVState as State } from '@/store';

const state: State = {
  policy: {
    vehicles: [],
    subTotal: undefined,
    isAvailable: undefined,
    financeCostPerMonth: undefined,
    insurer: {
      footnotes: undefined,
      insurerName: undefined,
      moreInfo: undefined,
      rating: undefined,
      text: undefined
    }
  },
  answers: {
    id: undefined,
    largeClaim: undefined,
    cmvLargeClaims: [],
    isCriminalOffense: undefined,
    cmvCriminalOffenses: [],
    driverLicenceIssues: undefined,
    cmvDriverLicenceIssues: [],
    driverDeclinedClaim: undefined,
    cmvDriverDeclinedClaims: [],
    vehicles: []
  }
};

export default state;
