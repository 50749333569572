import Vue from '@/vue';
import VueRouter from 'vue-router';
import routes from './routes';
import stateMachine from './stateMachine';
import { preventDirectFormNavigation } from "@/router/preventDirectFormNavigation";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(stateMachine);
router.beforeEach(preventDirectFormNavigation);

export default router;
