var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticStyle:{"border-color":"transparent"},attrs:{"align":"center","no-body":""}},[_c('b-card-body',{staticClass:"p-0"},[_c('h6',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.emitSubmit($event)}}},[_c('b-form-group',{attrs:{"label":"What happened?","label-class":"font-weight-light","label-align":_vm.$formOptions.labelAlign,"label-align-lg":_vm.$formOptions.labelAlignLg,"label-cols-lg":_vm.$formOptions.labelCols,"content-cols-lg":_vm.$formOptions.inputCols}},[_c(_vm.whatHappenedInputType,_vm._b({tag:"component",staticClass:"font-weight-light",attrs:{"options":_vm.eventOptions,"formatter":_vm.$format.maxLength(255)},on:{"input":function($event){_vm.emitInput();
            _vm.$v.value.whatHappened.$touch();}},model:{value:(_vm.value.whatHappened),callback:function ($$v) {_vm.$set(_vm.value, "whatHappened", $$v)},expression:"value.whatHappened"}},'component',_vm.whatHappenedInputOptions,false)),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$isValid(_vm.$v.value.whatHappened)}},[_vm._v("\n          Field is required\n        ")])],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":_vm.cShowDescriptionField}},[_c('b-form-group',{attrs:{"label":"Describe what happened","label-class":"font-weight-light","label-align":_vm.$formOptions.labelAlign,"label-align-lg":_vm.$formOptions.labelAlignLg,"label-cols-lg":_vm.$formOptions.labelCols,"content-cols-lg":_vm.$formOptions.inputCols}},[_c('b-textarea',{staticClass:"font-weight-light",attrs:{"formatter":_vm.$format.maxLength(255)},on:{"input":function($event){_vm.emitInput();
              _vm.$v.value.whatHappenedOther.$touch();}},model:{value:(_vm.value.whatHappenedOther),callback:function ($$v) {_vm.$set(_vm.value, "whatHappenedOther", $$v)},expression:"value.whatHappenedOther"}}),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$isValid(_vm.$v.value.whatHappenedOther)}},[_vm._v("\n            Field is required\n          ")])],1)],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Approximate date of loss","label-class":"font-weight-light","label-align":_vm.$formOptions.labelAlign,"label-align-lg":_vm.$formOptions.labelAlignLg,"label-cols-lg":_vm.$formOptions.labelCols,"content-cols-lg":_vm.$formOptions.inputCols}},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('b-select',{staticClass:"font-weight-light",attrs:{"placeholder":"Month","options":_vm.dateMonthOptions},on:{"input":function($event){_vm.emitInput();
                _vm.$v.value.claimMonth.$touch();}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Month")])]},proxy:true}]),model:{value:(_vm.value.claimMonth),callback:function ($$v) {_vm.$set(_vm.value, "claimMonth", $$v)},expression:"value.claimMonth"}})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"5"}},[_c('b-select',{staticClass:"font-weight-light",attrs:{"options":_vm.dateYearOptions},on:{"input":function($event){_vm.emitInput();
                _vm.$v.value.claimYear.$touch();}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Year")])]},proxy:true}]),model:{value:(_vm.value.claimYear),callback:function ($$v) {_vm.$set(_vm.value, "claimYear", $$v)},expression:"value.claimYear"}})],1)],1),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$isValid(_vm.$v.value.claimMonth) && _vm.$isValid(_vm.$v.value.claimYear)}},[_vm._v("\n          Field is required\n        ")])],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Approximate claim value","label-class":"font-weight-light","label-align":_vm.$formOptions.labelAlign,"label-align-lg":_vm.$formOptions.labelAlignLg,"label-cols-lg":_vm.$formOptions.labelCols,"content-cols-lg":_vm.$formOptions.inputCols}},[_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-input',{directives:[{name:"cleave",rawName:"v-cleave",value:({
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
              numeralIntegerScale: 7,
              numeralPositiveOnly: true,
              swapHiddenInput: true,
            }),expression:"{\n              numeral: true,\n              numeralThousandsGroupStyle: 'thousand',\n              numeralIntegerScale: 7,\n              numeralPositiveOnly: true,\n              swapHiddenInput: true,\n            }"}],staticClass:"font-weight-light",on:{"input":function($event){_vm.emitInput();
              _vm.$v.value.claimValue.$touch();}},model:{value:(_vm.value.claimValue),callback:function ($$v) {_vm.$set(_vm.value, "claimValue", $$v)},expression:"value.claimValue"}}),_vm._v(" "),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$isValid(_vm.$v.value.claimValue)}},[_vm._v("\n            Field is required\n          ")])],1)],1)],1),_vm._v(" "),_vm._t("footer")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }