import { AnswersCmv, PolicyVehicle } from '@/api';

import { CMVState } from '../states';

import Vue from '@/vue';

export const addVehicle = (
  state: CMVState,
  {
    vehicle
  }: {
    vehicle: PolicyVehicle;
  }
): void => {
  state.answers.vehicles?.push(vehicle);
};

export const removeVehicle = (
  state: CMVState,
  {
    vehicle
  }: {
    vehicle: PolicyVehicle;
  }
): void => {
  if (state.answers.vehicles !== undefined) {
    const index = state.answers.vehicles.findIndex(
      (v: PolicyVehicle) => v.id === vehicle.id
    );
    if (
      index !== undefined &&
      index !== -1 &&
      state.answers.vehicles !== undefined
    ) {
      Vue.delete(state.answers.vehicles, index);
    }
  }
};

export const removeLastVehicle = (state: CMVState): void => {
  if (
    state.answers.vehicles !== undefined &&
    state.answers.vehicles.length > 0
  ) {
    Vue.delete(state.answers.vehicles, state.answers.vehicles.length - 1);
  }
};

export const updateVehicle = (
  state: CMVState,
  {
    vehicle
  }: {
    vehicle: PolicyVehicle;
  }
): void => {
  if (state.answers.vehicles !== undefined) {
    const index = state.answers.vehicles.findIndex(
      (v: PolicyVehicle) => v.id === vehicle.id
    );
    if (
      index !== undefined &&
      index !== -1 &&
      state.answers.vehicles !== undefined
    ) {
      Vue.set(state.answers.vehicles, index, vehicle);
    }
  }
};

export const answers = (
  state: CMVState,
  {
    id = undefined,
    largeClaim = undefined,
    cmvLargeClaims = undefined,
    cmvCriminalOffenses = undefined,
    cmvDriverDeclinedClaims = undefined,
    cmvDriverLicenceIssues = undefined,
    isCriminalOffense = undefined,
    driverLicenceIssues = undefined,
    driverDeclinedClaim = undefined,
    vehicles = undefined
  }: AnswersCmv
): void => {
  const idNull = id === undefined || id === null;

  if (!idNull) {
    Vue.set(state.answers, 'id', id);
  }

  if (largeClaim !== undefined && largeClaim !== null) {
    Vue.set(state.answers, 'largeClaim', largeClaim);
  }
  if (
    cmvLargeClaims !== undefined &&
    (!idNull || cmvLargeClaims.length !== 0)
  ) {
    Vue.set(state.answers, 'cmvLargeClaims', cmvLargeClaims);
  }

  if (isCriminalOffense !== undefined && isCriminalOffense !== null) {
    Vue.set(state.answers, 'isCriminalOffense', isCriminalOffense);
  }
  if (
    cmvCriminalOffenses !== undefined &&
    (!idNull || cmvCriminalOffenses.length !== 0)
  ) {
    Vue.set(state.answers, 'cmvCriminalOffenses', cmvCriminalOffenses);
  }

  if (driverDeclinedClaim !== undefined && driverDeclinedClaim !== null) {
    Vue.set(state.answers, 'driverDeclinedClaim', driverDeclinedClaim);
  }
  if (
    cmvDriverDeclinedClaims !== undefined &&
    (!idNull || cmvDriverDeclinedClaims.length !== 0)
  ) {
    Vue.set(state.answers, 'cmvDriverDeclinedClaims', cmvDriverDeclinedClaims);
  }

  if (driverLicenceIssues !== undefined && driverLicenceIssues !== null) {
    Vue.set(state.answers, 'driverLicenceIssues', driverLicenceIssues);
  }
  if (
    cmvDriverLicenceIssues !== undefined &&
    (!idNull || cmvDriverLicenceIssues.length !== 0)
  ) {
    Vue.set(state.answers, 'cmvDriverLicenceIssues', cmvDriverLicenceIssues);
  }

  if (vehicles !== undefined && (!idNull || vehicles.length !== 0)) {
    Vue.set(state.answers, 'vehicles', vehicles);
  }
};

export const reset = (state: CMVState): void => {
  Vue.set(state, 'policy', {
    vehicles: [],
    subTotal: undefined,
    isAvailable: undefined,
    financeCostPerMonth: undefined
  });
  Vue.set(state, 'answers', {
    id: undefined,
    largeClaim: undefined,
    cmvLargeClaims: [],
    isCriminalOffense: undefined,
    cmvCriminalOffenses: [],
    driverLicenceIssues: undefined,
    cmvDriverLicenceIssues: [],
    driverDeclinedClaim: undefined,
    cmvDriverDeclinedClaims: [],
    vehicles: []
  });
};
