











import Vue from '@/vue';
import Component from 'vue-class-component';
import BiLoading from './components/bi-loading.vue';

@Component
export default class App extends Vue {
  mounted(): void {
    this.$loadingModal = this.$refs.loadingModal as BiLoading;
  }
}
