




















































































import Vue from '@/vue';
import { BModal } from 'bootstrap-vue';
import Component from 'vue-class-component';

@Component
export default class BiTooltip extends Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
    buttons: {
      type: Object,
      required: false,
      default: () => {
        return {
          ok: {
            text: 'Ok',
            variant: 'primary',
          },
        };
      },
    },
    display: {
      type: String,
      required: false,
      default: '',
    },
    popoverPlacement: {
      type: String,
      required: false,
      default: 'right',
    },
    modalCentered: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  id: string = Math.floor(Math.random() * 10000).toString();

  showTooltip(): void {
    this.$emit('click');
    if (
      this.content ||
      this.$slots.content ||
      this.title ||
      this.$slots.title
    ) {
      (this.$refs.tooltipModal as BModal).show();
    }
  }

  get displayAtBreakpoint(): boolean {
    if (this.display.startsWith('lt-')) {
      const breakpoint = this.display.split('-')[1];
      return this.$screen.xs && !this.$screen[breakpoint];
    } else if (this.display.startsWith('gt-')) {
      const breakpoint = this.display.split('-')[1];
      return this.$screen[breakpoint];
    }
    return true;
  }
}
