import {
  Answers,
  AnswersCmv,
  PackageAnswersApi,
  PackageDashboardApi, PackageForDashboard,
  PackagePolicyVehicleApi,
  PolicyType,
  PolicyVehicle
} from '@/api';
import { ActionContext } from 'vuex';
import { CMVState, State } from '../states';

export const save = async (
  { state, commit }: ActionContext<CMVState, State>,
  {
    hash,
    answers = undefined,
    create = false
  }: {
    hash: string;
    answers?: AnswersCmv;
    create?: boolean;
  }
): Promise<void> => {
  commit('answers', answers);
  if (state.answers.id !== undefined && state.answers.id !== null) {
    await new PackageAnswersApi().patchPackagePolicyGroupAnswers(
      hash,
      PolicyType.Cmv,
      {
        data: { answers }
      }
    );
  } else if (create) {
    const {
      data: response
    } = await new PackageAnswersApi().postPackagePolicyGroupAnswers(
      hash,
      PolicyType.Cmv,
      {
        data: { answers: state.answers }
      }
    );
    if (response.data !== undefined) {
      commit('answers', response.data.answers);
    }
  }
};

export const getAnswers = async (
  { commit }: ActionContext<CMVState, State>,
  { hash }: { hash: string }
): Promise<void> => {
  const answers = (
    await new PackageAnswersApi().getPackagePolicyGroupAnswers(
      hash,
      PolicyType.Cmv
    )
  ).data.data?.answers;
  if (answers !== undefined) {
    commit('answers', answers);
  }
};

export const saveVehicle = async (
  { state, commit }: ActionContext<CMVState, State>,
  {
    hash,
    vehicle,
    create = false,
    dashboard = false
  }: {
    hash: string;
    vehicle: PolicyVehicle;
    create?: boolean;
    dashboard?: boolean;
  }
): Promise<Answers | PackageForDashboard | undefined> => {
  if (vehicle.id !== undefined) {
    if (!dashboard) {
      return (await new PackageAnswersApi().patchPackagePolicyGroupAnswers(
        hash,
        PolicyType.Cmv,
        {
          data: {
            answers: {
              vehicles: [vehicle]
            }
          }
        }
      )).data.data;
    } else {
      return (await new PackageDashboardApi().patchPackageDashboardPolicy(
        hash,
        PolicyType.Cmv,
        {
          data: vehicle
        }
      )).data.data;
    }
  } else {
    const vehicles = state.answers.vehicles || [];
    if (
      vehicles.length !== 0 &&
      vehicles[vehicles.length - 1].id === undefined
    ) {
      commit('removeLastVehicle');
    }
    commit('addVehicle', { vehicle });
    if (create) {
      const {
        data
      } = await new PackageAnswersApi().postPackagePolicyGroupAnswers(
        hash,
        PolicyType.Cmv,
        {
          data: {
            answers: {
              vehicles: [vehicle]
            }
          }
        }
      );
      commit('answers', data.data?.answers || {});
      return data.data;
    }
  }
};

export const getVehicles = async (
  { commit }: ActionContext<CMVState, State>,
  { hash }: { hash: string }
): Promise<void> => {
  const policyVehicles = (
    await new PackagePolicyVehicleApi().getPackagePolicyVehicles(hash, 'CMV')
  ).data.data;
  if (policyVehicles !== undefined) {
    commit('answers', { policyVehicles });
  }
};
