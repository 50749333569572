import { PackageApi, PackageStatusInData } from '@/api';
import { AxiosResponse } from 'axios';
import { NavigationGuard } from 'vue-router';

export function getStatusPaths(
  packageHash: string
): Record<string, { preferred: string | (() => string); allowed: string[] }> {
  return {
    ReadyRenewal: {
      preferred: `/quote/${packageHash}/your-details`,
      allowed: [
        `/quote/${packageHash}/your-details`,
        `/quote/${packageHash}/other-details`
      ]
    },
    Renewal: {
      preferred: `/quote/${packageHash}/your-details`,
      allowed: [
        `/quote/${packageHash}/your-details`,
        `/quote/${packageHash}/other-details`,
        `/quote/${packageHash}/dashboard`,
        `/quote/${packageHash}/form`
      ]
    },
    Draft: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [
        `/quote/${packageHash}/your-details`,
        `/quote/${packageHash}/other-details`,
        `/quote/${packageHash}/dashboard`,
        `/quote/${packageHash}/form`
      ]
    },
    Saved: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [
        `/quote/${packageHash}/your-details`,
        `/quote/${packageHash}/other-details`,
        `/quote/${packageHash}/dashboard`,
        `/quote/${packageHash}/form`
      ]
    },
    AutoSaved: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [
        `/quote/${packageHash}/your-details`,
        `/quote/${packageHash}/other-details`,
        `/quote/${packageHash}/dashboard`,
        `/quote/${packageHash}/form`
      ]
    },
    Quoted: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [
        `/quote/${packageHash}/your-details`,
        `/quote/${packageHash}/other-details`,
        `/quote/${packageHash}/dashboard`,
        `/quote/${packageHash}/form`
      ]
    },
    ReferForQuote: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [`/quote/${packageHash}/dashboard`]
    },
    SubmittedReferredQuote: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [`/quote/${packageHash}/dashboard`]
    },
    ApplicationStarted: {
      preferred: `/quote/${packageHash}/apply`,
      allowed: [`/quote/${packageHash}/apply`]
    },
    ProceedToPayment: {
      preferred: `/quote/${packageHash}/payment`,
      allowed: [
        `/quote/${packageHash}/apply`,
        `/quote/${packageHash}/payment`,
        `/quote/${packageHash}/payment/thanks`
      ]
    },
    AwaitingPayment: {
      preferred: `/quote/${packageHash}/payment/thanks`,
      allowed: [`/quote/${packageHash}/payment/thanks`]
    },
    Applied: {
      preferred: `/quote/${packageHash}/payment`,
      allowed: [
        `/quote/${packageHash}/apply`,
        `/quote/${packageHash}/payment`,
        `/quote/${packageHash}/payment/thanks`
      ]
    },
    Accepted: {
      preferred: `/quote/${packageHash}/apply`,
      allowed: [`/quote/${packageHash}/apply`]
    },
    Declined: {
      preferred: `/quote/${packageHash}/declined`,
      allowed: [`/quote/${packageHash}/declined`]
    },
    AuthorisedQuote: {
      preferred: `/quote/${packageHash}/dashboard`,
      allowed: [`/quote/${packageHash}/dashboard`]
    },
    Expired: {
      preferred: `/quote/${packageHash}/expired`,
      allowed: [`/quote/${packageHash}/expired`]
    },
    Superseded: {
      preferred: `/quote/${packageHash}/superseded`,
      allowed: [`/quote/${packageHash}/superseded`]
    },
    Current: {
      preferred: `/quote/${packageHash}/current`,
      allowed: [`/quote/${packageHash}/current`]
    }
  };
}

async function isResponseSuccess(
  response: AxiosResponse<any>
): Promise<boolean> {
  return !(response.status === 404 || response.status === 500);
}

function valueExists(value: string | null | undefined) {
  return !(value === undefined || value === null || value.trim() === '');
}

function getIsAllowedPath(path: string, allowedPaths: string[]) {
  for (const allowedPath of allowedPaths) {
    if (path.startsWith(allowedPath)) {
      return true;
    }
  }
  return false;
}

function resolvePreferredStatusPath(preferred: string | (() => string)) {
  if (typeof preferred === 'function') {
    return preferred();
  } else {
    return preferred;
  }
}

const stateMachine: NavigationGuard = async (
  to,
  from,
  next
): Promise<void> => {
  const hasHashParam = to.matched
    .map(r => r.path.includes(':packageHash'))
    .includes(true);
  if (
    hasHashParam &&
    (
      process.env.NODE_ENV !== 'development' || (
      process.env.VUE_APP_NAVGUARD === undefined ||
      process.env.VUE_APP_NAVGUARD !== 'false')
    )
  ) {
    const packageHash = to.params.packageHash;
    let response:
      | AxiosResponse<PackageStatusInData>
      | undefined = undefined;
    if (!to.meta.noHashAllowed) {
      if (!valueExists(packageHash)) {
        next('/');
        return;
      } else {
        try {
          response = await new PackageApi().getPackageStatus(
            packageHash
          );
          if (!(await isResponseSuccess(response))) {
            next('/');
            return;
          }
        } catch (error) {
          if (!(await isResponseSuccess(error.response))) {
            next('/');
            return;
          }
        }
      }
    } else if (!valueExists(packageHash)) {
      next();
      return;
    }

    if (response === undefined) {
      response = await new PackageApi().getPackageStatus(
        packageHash
      );
    }
    const status = response.data.data?.status;
    if (status !== undefined) {
      const statusPaths = getStatusPaths(packageHash)[status] ??
      {
        preferred: '/404',
        allowed: []
      };

      if (getIsAllowedPath(to.path, statusPaths.allowed) === false) {
        const preferredPath = resolvePreferredStatusPath(
          statusPaths.preferred
        );
        next(preferredPath);
        return;
      }
    }
  }
  next();
};

export default stateMachine;
