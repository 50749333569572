




























































































import Component from 'vue-class-component';
import Vue from '@/vue';

@Component
export default class WelcomePage extends Vue {
  modalVisible = false;
  show() {
    this.modalVisible = true;
  }
  hide() {
    this.modalVisible = false;
  }
  click() {
    this.$emit('click');
  }
}
