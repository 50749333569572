
















































































































import Vue from '@/vue';
import Component from 'vue-class-component';

@Component
export default class DashboardPremiumSummaryView extends Vue.extend({
  props: {
    total: {
      type: [String, Number],
      required: true,
    },
    totalMonthly: {
      type: [String, Number],
      required: false,
    },
    subTotal: {
      type: [String, Number],
      required: true,
    },
    gst: {
      type: [String, Number],
      required: true,
    },
    commission: {
      type: [String, Number],
      required: true,
    },
    adminFee: {
      type: [String, Number],
      required: true,
    },
  },
}) {
  get totalFixed(): string {
    if (typeof this.total === 'number') {
      return this.total.toFixed(2);
    } else {
      return this.total;
    }
  }
  get subTotalFixed(): string {
    if (typeof this.subTotal === 'number') {
      return this.subTotal.toFixed(2);
    } else {
      return this.subTotal;
    }
  }
  get gstFixed(): string {
    if (typeof this.gst === 'number') {
      return this.gst.toFixed(2);
    } else {
      return this.gst;
    }
  }
  get totalMonthlyFixed(): string {
    if (typeof this.totalMonthly === 'number') {
      return this.totalMonthly.toFixed(2);
    } else {
      return this.totalMonthly;
    }
  }
  get commissionFixed(): string {
    if (typeof this.commission === 'number') {
      return this.commission.toFixed(2);
    } else {
      return this.commission;
    }
  }
  get adminFeeFixed(): string {
    if (typeof this.adminFee === 'number') {
      return this.adminFee.toFixed(2);
    } else {
      return this.adminFee;
    }
  }
}
