import { AnswersMdt, PackageAnswersApi } from '@/api';
import { ActionContext } from 'vuex';
import { State, MDTState } from '../states';

export const save = async (
  { state, commit }: ActionContext<MDTState, State>,
  {
    hash,
    answers,
    create = false
  }: {
    hash: string;
    answers: AnswersMdt;
    create: boolean;
  }
): Promise<void> => {
  commit('answers', answers);
  if (state.answers.id !== undefined && state.answers.id !== null) {
    await new PackageAnswersApi().patchPackagePolicyGroupAnswers(hash, 'MDT', {
      data: { answers }
    });
  } else if (create) {
    const {
      data: response
    } = await new PackageAnswersApi().postPackagePolicyGroupAnswers(
      hash,
      'MDT',
      {
        data: { answers }
      }
    );
    if (response.data !== undefined) {
      commit('answers', response.data.answers);
    }
  }
};

export const getAnswers = async (
  { commit }: ActionContext<MDTState, State>,
  { hash }: { hash: string }
): Promise<void> => {
  const answers = (
    await new PackageAnswersApi().getPackagePolicyGroupAnswers(hash, 'MDT')
  ).data.data?.answers;
  if (answers !== undefined) {
    commit('answers', answers);
  }
};
