








































import { PolicyClaim } from '@/api';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';
import BiClaimItem from './bi-claim-item.vue';

@Component
export default class BiClaimList extends Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: 'Claim',
    },
    addButtonText: {
      type: String,
      required: false,
      default: 'Add Claim',
    },
    removeButtonText: {
      type: String,
      required: false,
      default: 'Remove Claim',
    },
    value: {
      type: Array as () => Array<PolicyClaim>,
      default: (): PolicyClaim[] => [],
    },
    eventOptions: {
      type: Array,
      required: false,
      default: (): { value: string | number; text: string }[] => [],
    },
    dateMonthOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    dateYearOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    whatHappenedInputType: {
      type: String,
      required: false,
      default: 'b-select',
    },
    whatHappenedInputOptions: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    showDescriptionField: {
      type: [Boolean, Function] as PropType<
        boolean | ((value: PolicyClaim) => boolean)
      >,
      required: false,
      default: () =>
        function (value: PolicyClaim): boolean {
          return value?.whatHappened?.toLowerCase() === 'other';
        },
    },
  },
}) {
  touch(): void {
    if (this.$refs.claimItem) {
      for (const claim of this.$refs.claimItem as BiClaimItem[]) {
        claim.touch();
      }
    }
  }

  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }

  addItem(): void {
    this.value.push({
      whatHappened: undefined,
      whatHappenedOther: undefined,
      claimMonth: undefined,
      claimYear: undefined,
      claimValue: undefined,
    });
    this.emitInput();
  }

  removeItem(index: number): void {
    this.value.splice(index, 1);
    this.emitInput();
  }
}
