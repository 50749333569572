import { MDTState } from '..';

export const complete = (state: MDTState): boolean => {
  return state.answers.id !== undefined && state.answers.id !== null;
};

export const extraQuestionsComplete = (state: MDTState): boolean => {
  return (
    state.answers.scaffoldingValue !== undefined &&
    state.answers.scaffoldingValue !== null
  );
};
