import {
  PackageForDashboard,
  PackageInfo,
  PolicyForDashboard,
  PolicyLiabilityForDashboard,
  PolicyVehicleForDashboard
} from '@/api';
import Vue from 'vue';
import { LiabilityPolicyType } from '../states';

export const info = (state: PackageForDashboard, _info: PackageInfo): void => {
  if (state.info === undefined) {
    Vue.set(state, 'info', {});
  }

  if (state.info !== undefined) {
    for (const [k, v] of Object.entries(_info)) {
      if (v !== undefined) {
        Vue.set(state.info, k, v);
      }
    }
  }
};

export const summary = (
  state: PackageForDashboard,
  {
    id = state.id,
    hash = state.hash,
    supersededBy = state.supersededBy,
    isRenewal = state.isRenewal,
    hasPayment = state.hasPayment,
    info: _info = state.info,
    promoCode = state.promoCode,
    status = state.status,
    submitDate = state.submitDate,
    policies = state.policies,
    summary: _summary = state.summary
  }: PackageForDashboard
): void => {
  if (id !== undefined) {
    Vue.set(state, 'id', id);
  }
  if (hash !== undefined) {
    Vue.set(state, 'hash', hash);
  }
  if (supersededBy !== undefined) {
    Vue.set(state, 'supersededBy', supersededBy);
  }
  if (isRenewal !== undefined) {
    Vue.set(state, 'isRenewal', isRenewal);
  }
  if (hasPayment !== undefined) {
    Vue.set(state, 'hasPayment', hasPayment);
  }
  if (_info !== undefined) {
    info(state, _info);
  }
  if (promoCode !== undefined) {
    Vue.set(state, 'promoCode', promoCode);
  }
  if (status !== undefined) {
    Vue.set(state, 'status', status);
  }
  if (submitDate !== undefined) {
    Vue.set(state, 'submitDate', submitDate);
  }
  if (policies !== undefined) {
    Vue.set(state, 'policies', policies);
  }
  if (_summary !== undefined) {
    Vue.set(state, 'summary', _summary);
  }
};

export const policy = (
  state: PackageForDashboard,
  {
    type,
    data
  }: {
    type:
      | LiabilityPolicyType
      | 'toolsAndEquipment'
      | 'commercialVehicles';
    data:
      | PolicyForDashboard
      | PolicyLiabilityForDashboard
      | PolicyVehicleForDashboard;
  }
): void => {
  if (
    type === LiabilityPolicyType.PublicGeneral &&
    state.policies?.liability?.publicGeneral !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyLiabilityForDashboard;
    state.policies.liability.publicGeneral.id = id;
    state.policies.liability.publicGeneral.isInsured = isInsured;
    state.policies.liability.publicGeneral.coverOf = coverOf;
  }
  if (
    type === LiabilityPolicyType.Statutory &&
    state.policies?.liability?.statutory !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyLiabilityForDashboard;
    state.policies.liability.statutory.id = id;
    state.policies.liability.statutory.isInsured = isInsured;
    state.policies.liability.statutory.coverOf = coverOf;
  }
  if (
    type === LiabilityPolicyType.EmployeeDisputes &&
    state.policies?.liability?.employeeDisputes !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyLiabilityForDashboard;
    state.policies.liability.employeeDisputes.id = id;
    state.policies.liability.employeeDisputes.isInsured = isInsured;
    state.policies.liability.employeeDisputes.coverOf = coverOf;
  }
  if (
    type === LiabilityPolicyType.Employers &&
    state.policies?.liability?.employers !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyLiabilityForDashboard;
    state.policies.liability.employers.id = id;
    state.policies.liability.employers.isInsured = isInsured;
    state.policies.liability.employers.coverOf = coverOf;
  }
  if (
    type === LiabilityPolicyType.CrimeFidelity &&
    state.policies?.liability?.crimeFidelity !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyLiabilityForDashboard;
    state.policies.liability.crimeFidelity.id = id;
    state.policies.liability.crimeFidelity.isInsured = isInsured;
    state.policies.liability.crimeFidelity.coverOf = coverOf;
  }
  if (
    type === LiabilityPolicyType.DirectorsAndOfficers &&
    state.policies?.liability?.directorsAndOfficers !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyLiabilityForDashboard;
    state.policies.liability.directorsAndOfficers.id = id;
    state.policies.liability.directorsAndOfficers.isInsured = isInsured;
    state.policies.liability.directorsAndOfficers.coverOf = coverOf;
  }
  if (
    type === LiabilityPolicyType.LegalProsecutionDefenceCosts &&
    state.policies?.liability?.legalProsecutionDefenceCosts !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyLiabilityForDashboard;
    state.policies.liability.legalProsecutionDefenceCosts.id = id;
    state.policies.liability.legalProsecutionDefenceCosts.isInsured = isInsured;
    state.policies.liability.legalProsecutionDefenceCosts.coverOf = coverOf;
  }

  if (
    type === 'toolsAndEquipment' &&
    state.policies?.toolsAndEquipment?.toolsAndEquipment !== undefined
  ) {
    const { id, isInsured, coverOf } = data as PolicyForDashboard;
    state.policies.toolsAndEquipment.toolsAndEquipment.id = id;
    state.policies.toolsAndEquipment.toolsAndEquipment.isInsured = isInsured;
    state.policies.toolsAndEquipment.toolsAndEquipment.coverOf = coverOf;
  }

  if (
    type === 'commercialVehicles' &&
    state.policies?.commercialVehicles?.vehicles !== undefined
  ) {
    const { id, isInsured } = data as PolicyVehicleForDashboard;
    const vehicleIndex = state.policies.commercialVehicles.vehicles.findIndex(
      v => v.id === id
    );
    state.policies.commercialVehicles.vehicles[
      vehicleIndex
    ].isInsured = isInsured;
  }
};

export const reset = (state: PackageForDashboard): void => {
  Vue.set(state, 'id', undefined);
  Vue.set(state, 'hash', undefined);
  Vue.set(state, 'status', undefined);
  Vue.set(state, 'submitDate', undefined);
  Vue.set(state, 'info', {
    firstName: undefined,
    lastName: undefined,
    legalName: undefined,
    email: undefined,
    telephone: undefined,
    otherInfoInsurer: undefined,
    referral: undefined,
    referralOther: undefined,
    postalAddress: undefined,
    postalSuburb: undefined,
    postalCity: undefined,
    postalPostcode: undefined,
    primaryTrades: [],
    secondaryTrades: [],
    otherTrade: undefined,
    areaOfOp: undefined,
    mainSector: undefined,
    estTurnover: undefined,
    principalManual: undefined,
    coverStartDate: undefined,
    tradingStyle: undefined
  });
  Vue.set(state, 'promoCode', {
    code: undefined,
    label: undefined,
    discountValue: undefined
  });
  Vue.set(state, 'summary', {
    subTotal: undefined,
    gst: undefined,
    total: undefined,
    financeCostPerMonth: undefined
  });
  Vue.set(state, 'policies', {
    liability: {
      publicGeneral: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      statutory: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      employers: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      directorsAndOfficers: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      employeeDisputes: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      crimeFidelity: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      legalProsecutionDefenceCosts: {
        id: undefined,
        type: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      subTotal: undefined,
      isAvailable: undefined,
      insurerName: undefined,
      insurerFinancialStrengthRating: undefined,
      insurerUrl: undefined,
      insurerText: undefined,
      financeCostPerMonth: undefined
    },
    toolsAndEquipment: {
      toolsAndEquipment: {
        id: undefined,
        isInsured: undefined,
        coverOf: undefined,
        premium: undefined,
        refer: undefined
      },
      subTotal: undefined,
      isAvailable: undefined,
      insurerName: undefined,
      insurerFinancialStrengthRating: undefined,
      insurerUrl: undefined,
      insurerText: undefined,
      financeCostPerMonth: undefined
    },
    commercialVehicles: {
      vehicles: [],
      subTotal: undefined,
      isAvailable: undefined,
      insurerName: undefined,
      insurerFinancialStrengthRating: undefined,
      insurerUrl: undefined,
      insurerText: undefined,
      financeCostPerMonth: undefined
    },
  });
};
