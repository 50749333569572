var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.rowClass},[(!_vm.subPolicy)?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.$formOptions.formView.side,"lg":_vm.$formOptions.formView.sideLg}},[_c('b-img',{attrs:{"fluid":"","src":_vm.icon}})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":(_vm.$formOptions.formView.center + _vm.$formOptions.formView.side) / 2,"md":_vm.$formOptions.formView.centerLg / 2}},[_c('b-row',[_c('b-col',[_c('h5',{staticClass:"m-0",staticStyle:{"overflow-wrap":"normal"}},[_vm._v(_vm._s(_vm.title))])])],1),_vm._v(" "),_c('b-row',[_c('b-col',[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.$screen.md),expression:"$screen.md"}],staticClass:"m-0"},[_c('small',[_vm._v("Insurer: "+_vm._s(_vm.isInsured ? _vm.insurer.insurerName : 'N/A'))])])])],1),_vm._v(" "),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$screen.md),expression:"$screen.md"}],staticClass:"m-0 p-0 text-left",staticStyle:{"line-height":"1"},attrs:{"variant":"link","disabled":!_vm.isInsured},on:{"click":function($event){return _vm.$refs.fsrModal.show()}}},[_c('small',[_vm._v("View Financial Strength Rating")])]),_vm._v(" "),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$screen.md),expression:"!$screen.md"}],staticClass:"m-0 p-0 text-left",staticStyle:{"line-height":"1"},attrs:{"variant":"link","disabled":!_vm.isInsured},on:{"click":function($event){return _vm.$refs.fsrModal.show()}}},[_c('small',[_vm._v("View Insurer & Financial Strength Rating")])])],1)],1)],1),_vm._v(" "),_c('b-col',{staticClass:"text-right",attrs:{"cols":(_vm.$formOptions.formView.center + _vm.$formOptions.formView.side) / 2,"md":_vm.$formOptions.formView.centerLg / 2,"align-self":"end"}},[_c('h5',{class:{
          'm-0': true,
          'font-weight-light': _vm.moreInfoRequired,
        },staticStyle:{"overflow-wrap":"normal"}},[_vm._v("\n        "+_vm._s(_vm.premiumText)+"\n        "),(_vm.moreInfoRequired)?_c('bi-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"m-0 mb-3"},[_vm._v("\n              Based on your responses we can't quote this policy online. If we\n              require extra information one of our team will be in touch\n              shortly.\n            ")]),_vm._v(" "),_c('p',{staticClass:"m-0 mt-3"},[_vm._v("\n              You can still apply for the other policies in your package quote\n              now.\n            ")])]},proxy:true}],null,false,216020966)}):_vm._e()],1)]),_vm._v(" "),_c('b-col'),_vm._v(" "),_c('b-col',{attrs:{"cols":"12"}},[(
          _vm.isInsured &&
          _vm.premium !== null &&
          _vm.premium !== undefined &&
          !_vm.moreInfoRequired
        )?_c('b-collapse',{attrs:{"visible":_vm.showItems}},[_vm._t("default")],2):_vm._e()],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":_vm.$formOptions.formView.side,"lg":_vm.$formOptions.formView.sideLg}}),_vm._v(" "),(
            _vm.isInsured &&
            _vm.premium !== null &&
            _vm.premium !== undefined &&
            !_vm.moreInfoRequired &&
            _vm.$slots.default
          )?[_c('b-col',{staticClass:"pr-0"},[_c('bi-separator')],1),_vm._v(" "),_c('b-col',{staticClass:"px-0",attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showItems),expression:"showItems"}],attrs:{"variant":"link"},on:{"click":function($event){_vm.showItems = false}}},[_c('b-icon-chevron-up',{attrs:{"font-scale":"1.5"}})],1),_vm._v(" "),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showItems),expression:"!showItems"}],attrs:{"variant":"link"},on:{"click":function($event){_vm.showItems = true}}},[_c('b-icon-chevron-down',{attrs:{"font-scale":"1.5"}})],1)],1),_vm._v(" "),_c('b-col',{staticClass:"pl-0"},[_c('bi-separator')],1)]:[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$screen.lg),expression:"$screen.lg"}],attrs:{"cols":"0","lg":_vm.$formOptions.formView.centerLg / 2}}),_vm._v(" "),_c('b-col',{attrs:{"cols":12,"md":_vm.$formOptions.formView.center + _vm.$formOptions.formView.side,"lg":_vm.$formOptions.formView.centerLg / 2}},[_c('bi-separator',{attrs:{"margin-bottom":"0"}})],1)],_vm._v(" "),_c('b-col',{attrs:{"cols":"0","lg":_vm.$formOptions.formView.sideLg}})],2)],1)],1):(_vm.subPolicy)?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.$formOptions.formView.side,"lg":_vm.$formOptions.formView.sideLg}},[_c('b-img',{attrs:{"fluid":"","src":_vm.icon}})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":(_vm.$formOptions.formView.center + _vm.$formOptions.formView.side) / 2,"lg":_vm.$formOptions.formView.centerLg / 2}},[_c('b-row',[_c('b-col',[_vm._t("title",[_c('p',{staticClass:"m-0 font-weight-light",staticStyle:{"overflow-wrap":"normal"}},[_vm._v("\n              "+_vm._s(_vm.title)+"\n            ")])])],2)],1)],1),_vm._v(" "),_c('b-col',{staticClass:"text-right",attrs:{"cols":(_vm.$formOptions.formView.center + _vm.$formOptions.formView.side) / 2,"lg":_vm.$formOptions.formView.centerLg / 2,"align-self":"end"}},[_c('p',{class:{
          'm-0': true,
          'font-weight-light': _vm.moreInfoRequired,
        },style:({
          'font-weight': !_vm.moreInfoRequired ? 500 : '',
          'overflow-wrap': 'normal',
        })},[_vm._v("\n        "+_vm._s(_vm.premiumText)+"\n        "),(_vm.moreInfoRequired)?_c('bi-tooltip',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"m-0 mb-3"},[_vm._v("\n              Based on your responses we can't quote this policy online. If we\n              require extra information one of our team will be in touch\n              shortly.\n            ")]),_vm._v(" "),_c('p',{staticClass:"m-0 mt-3"},[_vm._v("\n              You can still apply for the other policies in your package quote\n              now.\n            ")])]},proxy:true}],null,false,216020966)}):_vm._e()],1),_vm._v(" "),_c('bi-separator',{directives:[{name:"show",rawName:"v-show",value:(_vm.$screen.md),expression:"$screen.md"}],attrs:{"margin-bottom":"0","margin-top":"2"}})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":_vm.$formOptions.formView.side,"lg":_vm.$formOptions.formView.sideLg}}),_vm._v(" "),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$screen.md),expression:"!$screen.md"}],attrs:{"cols":"12"}},[_c('bi-separator',{attrs:{"margin-bottom":"0","margin-top":"1","offset-left":2}})],1)],1):_vm._e(),_vm._v(" "),_c('bi-financial-strength-rating',{ref:"fsrModal",attrs:{"insurer":_vm.insurer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }