

































































































































































































































import Vue from '@/vue';
import { BModal } from 'bootstrap-vue';
import Component from 'vue-class-component';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment-timezone';

@Component
export default class BiQuoteSummary extends Vue.extend({
  props: {
    reference: {
      type: [String, Number],
      required: true,
    },
    legalName: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: false,
      default: moment().format('DD/MM/YYYY'),
    },
    total: {
      type: [String, Number],
      required: true,
    },
    totalMonthly: {
      type: [String, Number],
      required: false,
    },
    subTotal: {
      type: [String, Number],
      required: true,
    },
    gst: {
      type: [String, Number],
      required: true,
    },
    commission: {
      type: [String, Number],
      required: true,
    },
    adminFee: {
      type: [String, Number],
      required: true,
    },
    showPromoCode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  validations: {
    promoCode: { required },
  },
}) {
  promoCode = '';
  promoCodeValid = true;

  get totalFixed(): string {
    if (typeof this.total === 'number') {
      return this.total.toFixed(2);
    } else {
      return this.total;
    }
  }
  get subTotalFixed(): string {
    if (typeof this.subTotal === 'number') {
      return this.subTotal.toFixed(2);
    } else {
      return this.subTotal;
    }
  }
  get gstFixed(): string {
    if (typeof this.gst === 'number') {
      return this.gst.toFixed(2);
    } else {
      return this.gst;
    }
  }
  get totalMonthlyFixed(): string {
    if (typeof this.totalMonthly === 'number') {
      return this.totalMonthly.toFixed(2);
    } else {
      return this.totalMonthly;
    }
  }
  get commissionFixed(): string {
    if (typeof this.commission === 'number') {
      return this.commission.toFixed(2);
    } else {
      return this.commission;
    }
  }
  get adminFeeFixed(): string {
    if (typeof this.adminFee === 'number') {
      return this.adminFee.toFixed(2);
    } else {
      return this.adminFee;
    }
  }

  applyPromoCode(): void {
    this.promoCodeValid = false;
  }

  closePromoCodeModal(): void {
    (this.$refs.modalPromoCode as BModal).hide();
    this.promoCodeValid = true;
  }
}
