










































import { TradeLookupOption } from '@/store/lookups/getters';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class BiSelect extends Vue.extend({
  props: {
    options: {
      type: Array as () => TradeLookupOption[],
      required: false,
      default: (): { value: string | number; text: string }[] => [],
    },
    value: {
      type: Array as PropType<TradeLookupOption['value'][]>,
      required: false,
      default: (): TradeLookupOption['value'][] => [],
    },
    tagVariant: {
      type: String,
      required: false,
      default: 'primary',
    },
    tagsClass: {
      type: String,
      required: false,
      default: '',
    },
    tagsSize: {
      type: String,
      required: false,
      default: 'md',
    },
    tagPills: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectClass: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
  },
}) {
  selectValue = '';

  get availableOptions(): TradeLookupOption[] {
    return this.options.filter((o) => this.value.indexOf(o.value) === -1);
  }

  get valueOptions(): TradeLookupOption[] {
    const valueOptions = [];

    for (const v of this.value) {
      valueOptions.push(
        this.options.find((o) => o.value === v) ?? {
          text: v,
          value: v,
        }
      );
    }

    return valueOptions;
  }

  onOptionClick(option: TradeLookupOption['value']): void {
    this.selectValue = '';
    this.value.push(option);
    this.$emit('input', this.value);
  }

  removeTag(index: number): void {
    this.value.splice(index, 1);
  }
}
