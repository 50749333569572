import pkg from './package'
import global from './global'
import liability from './liability'
import toolsAndEquipment from './toolsAndEquipment'
import commercialVehicles from './commercialVehicles'
import lookups from './lookups'
import finance from './finance'
import {saveBankInfo} from "@/store/finance/actions";

const stores = {
  package: pkg,
  global,
  liability,
  toolsAndEquipment,
  commercialVehicles,
  lookups,
  finance
}

export type Actions = {
  package: { [ a in keyof typeof stores.package.actions ]: `package/${a}` };
  global: { [ a in keyof typeof stores.global.actions ]: `global/${a}` };
  liability: { [ a in keyof typeof stores.liability.actions ]: `liability/${a}` };
  toolsAndEquipment: { [ a in keyof typeof stores.toolsAndEquipment.actions ]: `toolsAndEquipment/${a}` };
  commercialVehicles: { [ a in keyof typeof stores.commercialVehicles.actions ]: `commercialVehicles/${a}` };
  lookups: { [ a in keyof typeof stores.lookups.actions ]: `lookups/${a}` };
  finance: { [ a in keyof typeof stores.finance.actions ]: `finance/${a}` };
}

export type Mutations = {
  package: { [ m in keyof typeof stores.package.mutations ]: `package/${m}` };
  global: { [ m in keyof typeof stores.global.mutations ]: `global/${m}` };
  liability: { [ m in keyof typeof stores.liability.mutations ]: `liability/${m}` };
  toolsAndEquipment: { [ m in keyof typeof stores.toolsAndEquipment.mutations ]: `toolsAndEquipment/${m}` };
  commercialVehicles: { [ m in keyof typeof stores.commercialVehicles.mutations ]: `commercialVehicles/${m}` };
  lookups: { [ m in keyof typeof stores.lookups.mutations ]: `lookups/${m}` };
  // finance: { [ a in keyof typeof stores.lookups.actions ]: `lookups/${a}` };
}

export type Getters = {
  // package: { [ g in keyof typeof stores.package.getters ]: `package/${g}` };
  // global: { [ g in keyof typeof stores.global.getters ]: `global/${g}` };
  liability: { [ g in keyof typeof stores.liability.getters ]: `liability/${g}` };
  toolsAndEquipment: { [ g in keyof typeof stores.toolsAndEquipment.getters ]: `toolsAndEquipment/${g}` };
  commercialVehicles: { [ g in keyof typeof stores.commercialVehicles.getters ]: `commercialVehicles/${g}` };
  lookups: { [ g in keyof typeof stores.lookups.getters ]: `lookups/${g}` };
  // finance: { [ g in keyof typeof stores.lookups.getters ]: `lookups/${g}` };
}

export const actions: Actions = {
  package: {
    getSummary: 'package/getSummary',
    savePolicy: 'package/savePolicy',
    saveInfo: 'package/saveInfo',
    action: 'package/action',
    contact: 'package/contact'
  },
  global: {
    reset: 'global/reset'
  },
  liability: {
    getAnswers: 'liability/getAnswers',
    save: 'liability/save',
    savePolicy: 'liability/savePolicy'
  },
  toolsAndEquipment: {
    getAnswers: 'toolsAndEquipment/getAnswers',
    save: 'toolsAndEquipment/save'
  },
  commercialVehicles: {
    getAnswers: 'commercialVehicles/getAnswers',
    save: 'commercialVehicles/save',
    getVehicles: 'commercialVehicles/getVehicles',
    saveVehicle: 'commercialVehicles/saveVehicle'
  },
  lookups: {
    getGeneralLookup: 'lookups/getGeneralLookup',
    getRegionLookup: 'lookups/getRegionLookup',
    getCitiesLookup: 'lookups/getCitiesLookup',
    getSectorLookup: 'lookups/getSectorLookup',
    getTradeLookup: 'lookups/getTradeLookup',
    getLossOfUsePremiumLookup: 'lookups/getLossOfUsePremiumLookup',
    getInterestedPartyLookup: 'lookups/getInterestedPartyLookup',
  },
  finance: {
    createQuote: 'finance/createQuote',
    saveBankInfo: 'finance/saveBankInfo',
  },
}

export const mutations: Mutations = {
  package: {
    summary: 'package/summary',
    policy: 'package/policy',
    info: 'package/info',
    reset: 'package/reset'
  },
  global: {
    setEmailAsked: 'global/setEmailAsked',
    reset: 'global/reset'
  },
  liability: {
    isInsured: 'liability/isInsured',
    answers: 'liability/answers',
    setPolicyLiabilityForDashboard: 'liability/setPolicyLiabilityForDashboard',
    reset: 'liability/reset'
  },
  toolsAndEquipment: {
    isInsured: 'toolsAndEquipment/isInsured',
    answers: 'toolsAndEquipment/answers',
    reset: 'toolsAndEquipment/reset'
  },
  commercialVehicles: {
    answers: 'commercialVehicles/answers',
    addVehicle: 'commercialVehicles/addVehicle',
    removeVehicle: 'commercialVehicles/removeVehicle',
    removeLastVehicle: 'commercialVehicles/removeLastVehicle',
    updateVehicle: 'commercialVehicles/updateVehicle',
    reset: 'commercialVehicles/reset'
  },
  lookups: {
    general: 'lookups/general',
    region: 'lookups/region',
    cities: 'lookups/cities',
    sector: 'lookups/sector',
    trade: 'lookups/trade',
    lossOfUsePremiums: 'lookups/lossOfUsePremiums',
    wording: 'lookups/wording',
    interestedParty: 'lookups/interestedParty',
  }
}

export const getters: Getters = {
  // package: {},
  // global: {},
  liability: {
    complete: 'liability/complete'
  },
  toolsAndEquipment: {
    complete: 'toolsAndEquipment/complete',
    extraQuestionsComplete: 'toolsAndEquipment/extraQuestionsComplete'
  },
  commercialVehicles: {
    complete: 'commercialVehicles/complete',
    vehicleComplete: 'commercialVehicles/vehicleComplete'
  },
  lookups: {
    general: 'lookups/general',
    region: 'lookups/region',
    cities: 'lookups/cities',
    sector: 'lookups/sector',
    trade: 'lookups/trade',
    lossOfUsePremiums: 'lookups/lossOfUsePremiums',
    wording: 'lookups/wording',
    years: 'lookups/years',
    months: 'lookups/months',
    interestedParty: 'lookups/interestedParty',
  }
}
