



























import Vue from '@/vue';
import Component from 'vue-class-component';
import { FinancialSynergyInfo } from '@/api';
import { PropType } from 'vue';

interface Row {
  title?: string;
  content?: string;
  titleClass?: string | string[] | Record<string, boolean>;
  contentClass?: string | string[] | Record<string, boolean>;
}

@Component({
  props: {
    data: {
      type: Object as PropType<FinancialSynergyInfo>,
      required: true,
    },
  },
})
export default class FinanceDetailsView extends Vue {
  get financeInfoPeriodOfInsuranceHeading(): Row {
    return {
      titleClass: 'font-weight-light',
      contentClass: 'font-weight-light',
      title: 'Period of Insurance',
      content: `${this.$props.data.summary?.startDate} to ${this.$props.data.summary?.endDate}`,
    };
  }
  get financeInfoPeriodOfInsuranceBody(): Row[] {
    return [
      { title: 'Sub-Total', content: `$${this.$props.data.summary.subTotal}` },
      { title: 'GST', content: `$${this.$props.data.summary.gst}` },
      {},
      {
        titleClass: 'font-weight-bold',
        contentClass: 'font-weight-bold',
        title: 'Total',
        content: `$${this.$props.data.summary.total}`,
      },
    ];
  }
  get financeInfoMonthlyInstalmentsHeading(): Row {
    return { titleClass: 'font-weight-light', title: 'Monthly Instalments' };
  }
  get financeInfoMonthlyInstalmentsBody(): Row[] {
    return [
      {
        title: '10 Monthly Instalments of',
        content: `$${this.$props.data.instalments.monthlyInstalment}`,
      },
      {
        title: 'First Instalment Due',
        content: this.$props.data.instalments.firstInstalmentDate,
      },
      {
        title: 'Last Instalment Due',
        content: this.$props.data.instalments.lastInstalmentDate,
      },
      {
        title: 'Total Number of Instalments',
        content: this.$props.data.instalments.numberOfInstalments,
      },
    ];
  }
  get financeInfoFirstPaymentHeading(): Row {
    return { titleClass: 'font-weight-light', title: 'First Payment Amount' };
  }
  get financeInfoFirstPaymentBody(): Row[] {
    return [
      {
        title: 'Deposit',
        content: `$${this.$props.data.firstPayment.deposit}`,
      },
      {
        title: 'First Instalment',
        content: `$${this.$props.data.firstPayment.firstInstalment}`,
      },
      {
        title: 'Overdue Instalment',
        content: `$${this.$props.data.firstPayment.overdueInstalment}`,
      },
      {
        title: 'FSL Administration Fee',
        content: `$${this.$props.data.firstPayment.fslAdminFee}`,
      },
      {
        title: 'First Payment Required',
        content: `$${this.$props.data.firstPayment.firstPaymentRequired}`,
      },
    ];
  }
  get financeInfoInstalmentsHeading(): Row {
    return {
      titleClass: 'font-weight-light',
      title: 'Calculation of Instalments',
    };
  }
  get financeInfoInstalmentsBody(): Row[] {
    return [
      { title: 'Total', content: `$${this.$props.data.summary.total}` },
      {
        title: 'Less Deposit',
        content: `$${this.$props.data.summary.lessDeposit}`,
      },
      { title: 'Credit', content: `$${this.$props.data.summary.credit}` },
      {
        title: 'Cost of Credit',
        content: `$${this.$props.data.summary.costOfCredit}`,
      },
      {
        title: 'Total Loan',
        content: `$${this.$props.data.summary.totalLoan}`,
      },
    ];
  }
  get financeInfoInterestHeading(): Row {
    return { titleClass: 'font-weight-light', title: 'Interest' };
  }
  get financeInfoInterestBody(): Row[] {
    return [
      {
        title: 'Flat Rate %',
        content: `${this.$props.data.interest.flatRate * 100}%`,
      },
      {
        title: 'Interest Amount',
        content: `$${this.$props.data.interest.interestAmount}`,
      },
    ];
  }
}
