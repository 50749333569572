




















































import Vue from '@/vue';
import { BFormInput } from 'bootstrap-vue';
import Component from 'vue-class-component';

interface FEvent extends Event {
  target: HTMLInputElement & EventTarget;
  dataTransfer: DataTransfer;
}

@Component
export default class BiFile extends Vue.extend({
  props: {
    value: {
      type: Array,
      required: false,
      default: (): File[] => [],
    },
    accept: {
      type: String,
      required: false,
      default: '*',
    },
  },
}) {
  onFileChange(e: FEvent): void {
    if (e.target !== undefined && e.dataTransfer !== undefined) {
      const files = e.target.files || e.dataTransfer.files;
      this.value.push(...files);
    }
  }

  addFile(): void {
    (this.$refs.fileInput as BFormInput).click();
  }
  removeFile(index: number): void {
    this.value.splice(index, 1);
  }

  getFileIcon(filename: string): string {
    let extension: string[] | string = filename.split('.');
    extension = extension[extension.length - 1].toLowerCase();

    const iconMap: Record<string, string> = {
      doc: 'word',
      docx: 'word',
      zip: 'zip',
      rar: 'zip',
      pdf: 'richtext',
      jpg: 'image',
      png: 'image',
      gif: 'image',
      tif: 'image',
      xls: 'spreadsheet',
      xlsx: 'spreadsheet',
      ppt: 'slides',
      pptx: 'slides',
      exe: 'binary',
      txt: 'font',
    };

    if (iconMap[extension] !== undefined) {
      return `b-icon-file-earmark-${iconMap[extension]}`;
    } else {
      return 'b-icon-file-earmark';
    }
  }
}
