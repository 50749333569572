import Vue from '@/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueScreen from 'vue-screen';
import init from '@/init';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(Vuelidate);

Vue.use(VueScreen, 'bootstrap');

Vue.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: process.env.VUE_APP_ELASTIC_APM_RUM_SERVICE_NAME,
    serverUrl: process.env.VUE_APP_ELASTIC_APM_RUM_SERVER_URL,
    environment: process.env.VUE_APP_ELASTIC_APM_RUM_ENVIRONMENT,
    active: process.env.VUE_APP_ELASTIC_APM_RUM_ENVIRONMENT !== 'development',
    breakdownMetrics: true,
  },
})

Vue.config.productionTip = false;

// NOTE: to add instance properties, declare with `Vue.prototype.$variableName` in a default export function in a ./src/init/*.ts file and extend the vue interface module (see existing for examples)
init();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
