


























import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class BiLoading extends Vue.extend({
  props: {
    label: {
      type: String,
      required: false,
      default: 'Loading...',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
      required: false,
      default: 'lg',
    },
    spinnerClass: {
      type: String,
      required: false,
      default: 'mb-2',
    },
    labelClass: {
      type: String,
      required: false,
      default: 'm-0',
    },
    labelTag: {
      type: String,
      required: false,
      default: 'p',
    },
  },
}) {
  visible = false;
  get _size(): string {
    switch (this.size) {
      case 'xs':
        return '1rem';
      case 'sm':
        return '1.5rem';
      case 'lg':
        return '3rem';
      case 'md':
      default:
        return '2rem';
    }
  }

  show(value = true): boolean {
    this.visible = value;
    return this.visible;
  }
}
