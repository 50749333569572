
























































import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

interface Row {
  title?: string;
  content?: string;
  titleClass?: string | string[] | Record<string, boolean>;
  contentClass?: string | string[] | Record<string, boolean>;
}

@Component
export default class BiFinanceInfoSection extends Vue.extend({
  props: {
    heading: {
      type: Object as PropType<Row>,
      required: true,
    },
    body: {
      type: Array as PropType<Row[]>,
      required: true,
    },
  },
}) {}
