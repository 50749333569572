import {
  AnswersBli,
  PolicyLiability,
  PolicyLiabilityForDashboard
} from '@/api';
import Vue from '@/vue';

import { BLIState, LiabilityPolicyType } from '@/store';

export const isInsured = (
  state: BLIState,
  {
    policyType,
    isInsured: _isInsured
  }: {
    policyType: LiabilityPolicyType;
    isInsured?: boolean;
  }
): void => {
  const policy = state.policy[policyType];
  if (
    policyType !== undefined &&
    _isInsured !== undefined &&
    policy !== undefined
  ) {
    Vue.set(policy, 'isInsured', _isInsured);
  }
};

const setPolicyLiability = (
  state: BLIState,
  policyType: LiabilityPolicyType,
  policyAnswers: PolicyLiability
): void => {
  if (policyAnswers.id !== undefined && policyAnswers.id !== null) {
    Vue.set(
      state.answers[policyType] as PolicyLiability,
      'id',
      policyAnswers.id
    );
  }
  if (policyAnswers.type !== undefined && policyAnswers.type !== null) {
    Vue.set(
      state.answers[policyType] as PolicyLiability,
      'type',
      policyAnswers.type
    );
  }
  if (policyAnswers.limit !== undefined && policyAnswers.limit !== null) {
    Vue.set(
      state.answers[policyType] as PolicyLiability,
      'limit',
      policyAnswers.limit
    );
  }
  if (policyAnswers.excess !== undefined && policyAnswers.excess !== null) {
    Vue.set(
      state.answers[policyType] as PolicyLiability,
      'excess',
      policyAnswers.excess
    );
  }
  if (policyAnswers.premium !== undefined && policyAnswers.premium !== null) {
    Vue.set(
      state.answers[policyType] as PolicyLiability,
      'premium',
      policyAnswers.premium
    );
  }
};

export const setPolicyLiabilityForDashboard = (
  state: BLIState,
  {
    policyType,
    policy
  }: {
    policyType: LiabilityPolicyType;
    policy: PolicyLiabilityForDashboard;
  }
): void => {
  if (state.policy[policyType] === undefined) {
    state.policy[policyType] = {
      id: undefined,
      coverOf: undefined,
      isInsured: undefined,
      premium: undefined,
      refer: undefined,
      type: undefined
    };
  }

  if (policy.id !== undefined && policy.id !== null) {
    Vue.set(
      state.policy[policyType] as PolicyLiabilityForDashboard,
      'id',
      policy.id
    );
  }
  if (policy.coverOf !== undefined && policy.coverOf !== null) {
    Vue.set(
      state.policy[policyType] as PolicyLiabilityForDashboard,
      'type',
      policy.coverOf
    );
  }
  if (policy.isInsured !== undefined && policy.isInsured !== null) {
    Vue.set(
      state.policy[policyType] as PolicyLiabilityForDashboard,
      'limit',
      policy.isInsured
    );
  }
  if (policy.premium !== undefined && policy.premium !== null) {
    Vue.set(
      state.policy[policyType] as PolicyLiabilityForDashboard,
      'excess',
      policy.premium
    );
  }
  if (policy.refer !== undefined && policy.refer !== null) {
    Vue.set(
      state.policy[policyType] as PolicyLiabilityForDashboard,
      'premium',
      policy.refer
    );
  }
  if (policy.type !== undefined) {
    Vue.set(
      state.policy[policyType] as PolicyLiabilityForDashboard,
      'premium',
      policy.type
    );
  }
};

export const answers = (
  state: BLIState,
  {
    id = undefined,
    industrialSites = undefined,
    industrialWorkTypes = undefined,
    isHotwork = undefined,
    publicGeneral: publicGeneralLiability = undefined,
    statutory: statutoryLiability = undefined,
    employers: employersLiability = undefined,
    directorsAndOfficers: directorsAndOfficersLiability = undefined,
    employeeDisputes: employeeDisputesLiability = undefined,
    crimeFidelity: crimeFidelityLiability = undefined,
    legalProsecutionDefenceCosts: legalProsecutionDefenceCostsLiability = undefined,
    isMadeClaim = undefined,
    declaredInsolvent = undefined,
    involvedInSpecifiedActivity = undefined,
    involvedInSpecifiedActivityOther = undefined,
    bliLiabilityClaims = undefined
  }: AnswersBli
): void => {
  const idNull = id === undefined || id === null;

  if (!idNull) {
    Vue.set(state.answers, 'id', id);
  }

  if (publicGeneralLiability !== undefined && publicGeneralLiability !== null) {
    setPolicyLiability(
      state,
      LiabilityPolicyType.PublicGeneral,
      publicGeneralLiability
    );
  }
  if (statutoryLiability !== undefined && statutoryLiability !== null) {
    setPolicyLiability(
      state,
      LiabilityPolicyType.Statutory,
      statutoryLiability
    );
  }
  if (employersLiability !== undefined && employersLiability !== null) {
    setPolicyLiability(
      state,
      LiabilityPolicyType.Employers,
      employersLiability
    );
  }
  if (
    directorsAndOfficersLiability !== undefined &&
    directorsAndOfficersLiability !== null
  ) {
    setPolicyLiability(
      state,
      LiabilityPolicyType.DirectorsAndOfficers,
      directorsAndOfficersLiability
    );
  }
  if (
    employeeDisputesLiability !== undefined &&
    employeeDisputesLiability !== null
  ) {
    setPolicyLiability(
      state,
      LiabilityPolicyType.EmployeeDisputes,
      employeeDisputesLiability
    );
  }
  if (crimeFidelityLiability !== undefined && crimeFidelityLiability !== null) {
    setPolicyLiability(
      state,
      LiabilityPolicyType.CrimeFidelity,
      crimeFidelityLiability
    );
  }
  if (
    legalProsecutionDefenceCostsLiability !== undefined &&
    legalProsecutionDefenceCostsLiability !== null
  ) {
    setPolicyLiability(
      state,
      LiabilityPolicyType.LegalProsecutionDefenceCosts,
      legalProsecutionDefenceCostsLiability
    );
  }
  if (industrialSites !== undefined && industrialSites !== null) {
    Vue.set(state.answers, 'industrialSites', industrialSites);
  }
  if (industrialWorkTypes !== undefined && industrialWorkTypes !== null) {
    Vue.set(state.answers, 'industrialWorkTypes', industrialWorkTypes);
  }
  if (isHotwork !== undefined && isHotwork !== null) {
    Vue.set(state.answers, 'isHotwork', isHotwork);
  }

  if (isMadeClaim !== undefined && isMadeClaim !== null) {
    Vue.set(state.answers, 'isMadeClaim', isMadeClaim);
  }
  if (declaredInsolvent !== undefined && declaredInsolvent !== null) {
    Vue.set(state.answers, 'declaredInsolvent', declaredInsolvent);
  }
  if (involvedInSpecifiedActivity !== undefined && involvedInSpecifiedActivity !== null) {
    Vue.set(state.answers, 'involvedInSpecifiedActivity', involvedInSpecifiedActivity);
  }
  if (involvedInSpecifiedActivityOther !== undefined && involvedInSpecifiedActivityOther !== null) {
    Vue.set(state.answers, 'involvedInSpecifiedActivityOther', involvedInSpecifiedActivityOther);
  }
  if (
    bliLiabilityClaims !== undefined &&
    (!idNull || bliLiabilityClaims.length !== 0)
  ) {
    Vue.set(state.answers, 'bliLiabilityClaims', bliLiabilityClaims);
  }
};

export const reset = (state: BLIState): void => {
  Vue.set(state, 'policy', {
    publicGeneral: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    statutory: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    employers: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    directorsAndOfficers: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    employeeDisputes: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    crimeFidelity: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    },
    legalProsecutionDefenceCosts: {
      id: undefined,
      type: undefined,
      isInsured: false,
      coverOf: undefined,
      premium: undefined,
      refer: false
    }
  });
  Vue.set(state, 'answers', {
    industrialSites: undefined,
    industrialWorkTypes: undefined,
    isHotwork: undefined,
    isMadeClaim: undefined,
    bliLiabilityClaims: [],
    publicGeneral: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    statutory: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    employers: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    directorsAndOfficers: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    employeeDisputes: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    crimeFidelity: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    },
    legalProsecutionDefenceCosts: {
      id: undefined,
      type: undefined,
      limit: undefined,
      excess: undefined,
      premium: undefined
    }
  });
};
