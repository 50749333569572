







































import {
  PackageDashboardApi,
  PackageForDashboardPoliciesToolsAndEquipment,
  PolicyForDashboard,
  PolicyType,
} from '@/api';
import BiFormView from '@/components/bi-form-view/bi-form-view.vue';
import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class DashboardPolicyMdtView extends Vue.extend({
  props: {
    data: {
      type: Object as PropType<PackageForDashboardPoliciesToolsAndEquipment>,
    },
    complete: {
      type: Boolean,
      required: true,
      default: false,
    },
    showPremium: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
}) {
  get toolsAndEquipmentRenewing(): boolean {
    return (
      this.data.toolsAndEquipment?.id !== undefined &&
      this.data.toolsAndEquipment.id !== null &&
      this.data.isRenewal === true
    );
  }

  get isPaid(): boolean {
    return this.data?.isPayable === false || false;
  }

  get underwriting(): boolean {
    return this.data.toolsAndEquipment?.status === 'Underwriting-Insurer'
      || this.data.toolsAndEquipment?.status === 'Underwriting-Client'
      || this.data.toolsAndEquipment?.status === 'Underwriting-outside scheme'
  }

  get completeFormLabel(): string {
    return this.toolsAndEquipmentRenewing
      ? 'Confirm details to renew policy'
      : 'Complete form to add policy';
  }

  onToolsAndEquipmentIsInsuredChanged(to: boolean): void {
    if (
      this.data?.toolsAndEquipment !== undefined &&
      to === true &&
      (this.data.toolsAndEquipment.coverOf === undefined ||
        this.data.toolsAndEquipment.coverOf === null ||
        (this.data.toolsAndEquipment.coverOf !== undefined &&
          parseInt(this.data.toolsAndEquipment.coverOf) >=
            this.$toolValueLimit &&
          !this.$store.getters[
            this.$getters.toolsAndEquipment.extraQuestionsComplete
          ]))
    ) {
      const policyObject = this.data.toolsAndEquipment;
      this.$nextTick(() => {
        this.$set(policyObject, 'isInsured', false);
      });
      const coverOf =
        this.data.toolsAndEquipment.coverOf !== undefined &&
        this.data.toolsAndEquipment.coverOf !== null
          ? parseInt(this.data.toolsAndEquipment.coverOf)
          : 0;
      const page = coverOf < this.$toolValueLimit ? '1' : '3';
      this.moreInfoRequired('tools', page, this.save);
    } else {
      this.save();
    }
  }
  onToolsAndEquipmentCoverOfChanged(value: PolicyForDashboard): void {
    const oldValue =
      this.$store.state.package.policies.toolsAndEquipment.toolsAndEquipment
        .coverOf;
    if (
      value.coverOf !== undefined &&
      value.coverOf !== null &&
      (parseInt(value.coverOf) || 0) > this.$toolValueLimit &&
      !this.$store.getters[
        this.$getters.toolsAndEquipment.extraQuestionsComplete
      ]
    ) {
      this.moreInfoRequired('tools', '3', this.save, () => {
        if (this.data.toolsAndEquipment !== undefined) {
          this.data.toolsAndEquipment.coverOf = oldValue;
        }
      });
    } else {
      this.save();
    }
  }

  moreInfoRequired(
    form: string,
    page: string,
    okCallback?: (() => Promise<void>) | (() => void),
    cancelCallback?: (() => Promise<void>) | (() => void)
  ): void {
    this.$emit('more-info-required', form, page, okCallback, cancelCallback);
  }

  open(): void {
    (this.$refs.formView as BiFormView).open();
  }

  saving = false;
  async save(): Promise<void> {
    if (this.data?.toolsAndEquipment !== undefined) {
      this.saving = true;
      try {
        this.$emit(
          'update',
          (
            await new PackageDashboardApi().patchPackageDashboardPolicy(
              this.$packageHash,
              PolicyType.Mdt,
              {
                data: this.data.toolsAndEquipment,
              }
            )
          ).data.data
        );
      } catch (err) {
        console.error(err);
      } finally {
        this.saving = false;
      }
    }
  }
}
