
































































































import { PolicyEvent } from '@/api';
import Vue from '@/vue';
import Component from 'vue-class-component';
import { Validations } from 'vuelidate-property-decorators';
import { required, requiredUnless } from 'vuelidate/lib/validators';

@Component
export default class BiEventItem extends Vue.extend({
  props: {
    value: {
      type: Object,
      required: false,
      default: (): PolicyEvent => {
        return {
          whatHappened: '',
          penalty: '',
          eventMonth: undefined,
          eventYear: undefined,
        };
      },
    },
    dateMonthOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    dateYearOptions: {
      type: Array,
      required: true,
      default: (): { value: string | number; text: string }[] => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    noPenalty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  @Validations()
  validations = {
    value: {
      whatHappened: { required },
      penalty: {
        required: requiredUnless(() => {
          return this.noPenalty;
        }),
      },
      eventMonth: { required },
      eventYear: { required },
    },
  };

  touch(): void {
    this.$v.$touch();
  }
  emitInput(): void {
    this.$emit('input', this.value);
  }

  emitSubmit(): void {
    this.$emit('submit', this.value);
  }
}
