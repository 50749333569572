






































































































































































import Vue from '@/vue';
import { PropType } from 'vue';
import Component from 'vue-class-component';

@Component
export default class BiFormView extends Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    moreInfoLink: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false,
    },
    formLink: {
      type: String,
      required: true,
    },
    noEditForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    noCardLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    completeFormLabel: {
      type: String,
      required: false,
      default: 'Complete form to add policy',
    },
    editFormLabel: {
      type: String,
      required: false,
      default: 'Edit Form',
    },
    complete: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInsured: {
      type: Boolean,
      required: false,
      default: true,
    },
    premium: {
      type: [String, Number],
      required: false,
      default: '',
    },
    referred: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPremium: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPaid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}) {
  public showItems = this.complete;

  open(): void {
    this.showItems = true;
  }
  close(): void {
    this.showItems = false;
  }

  cardCols = {
    side: {
      xs: 2,
      lg: 1,
    },
    center: {
      xs: 8,
      lg: 10,
    },
  };

  get borderVariant(): string {
    return this.complete && this.isInsured ? 'primary' : '';
  }

  get displayedIcon(): string {
    if (this.icon !== undefined) {
      return this.complete && this.isInsured ? this.icon[0] : this.icon[1];
    }
    return '';
  }

  get hasNoEditForm(): boolean {
    return this.disabled || this.noEditForm;
  }
}
