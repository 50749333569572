// import { PackagePaymentApi } from '@/api';
import {ActionContext} from 'vuex';
import {State} from '@/store';
import {FinancialSynergyInfo, PackagePaymentApi, PaymentFinanceRequest, PaymentRequest} from "@/api";

export const createQuote = async (
  _: ActionContext<Record<string, never>, State>,
  { hash }: { hash: string }
): Promise<FinancialSynergyInfo> => {
  return (
      await new PackagePaymentApi().createPackagePaymentFinanceQuote(hash)
  ).data;
};

export const saveBankInfo = async (
  _: ActionContext<Record<string, never>, State>,
  { hash, info }: { hash: string; info: PaymentFinanceRequest }
): Promise<PaymentRequest | undefined> => {
  return (await new PackagePaymentApi().postPackagePaymentFinanceRequest(hash, info)).data.data;
};
